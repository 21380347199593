export default {
  title: "Vaccine Supply-Chain",
  icon: "pill",
  id: "vacctemp",
  description:
    "Pandemic scenario where backorder costs and variability are high",
  type: "beergame",
  config: {
    params: {
      totalWeeks: 20,
      transparent: false,
      timer: 60000,
      playTimer: false,
      product: "vaccine pack",
      timeUnit: "week",
      consumerDemand: [
        4,
        4,
        4,
        4,
        9,
        9,
        11,
        12,
        10,
        12,
        9,
        10,
        11,
        10,
        8,
        9,
        10,
        11,
        11,
        8
      ],
      configMessages: {
        allowChat: false,
        backlogMessage: {
          sentTo: [],
          active: true,
          message:
            "Watch out, you didn't have enough stock to fulfill the demand !",
          moreThan: 0
        },
        stockMessage: {
          sentTo: [],
          active: true,
          message:
            "Try to keep a reasonable amount of stock as it impacts your costs !",
          moreThan: 50
        },
        scheduleList: [
          {
            applyWeek: 0,
            message:
              "It's time to send your Order! Decide the quantity based on the previous demand, your stock and future planned deliveries.",
            to: ["all"]
          },
          {
            applyWeek: 4,
            message:
              "A new kind of flue is spreading in the world 😷. Fortunately your vaccine seems to be effective against it ! Be ready for an increase in demand..",
            to: ["all"]
          },
          {
            applyWeek: 11,
            message:
              "Remember that your mission is essential for global health, we can't afford any delay !",
            to: ["all"]
          }
        ]
      },
      currency: "$",
      debriefContent: [
        "Global Results",
        "Fill Rate",
        "Facility Charts",
        "Orders/Stock Charts",
        "Variability",
        "Lead times",
        "To Go Further"
      ]
    },
    facilities: {
      "1": {
        type: "shop",
        name: "Hospital",
        icon: "hospital-box",
        id: "1",
        supplier_id: "200",
        validatedOrder: false,
        stock: 12,
        backlog: 0,
        cost: 0,
        revenue: 0,
        allowBacklog: true,
        unitPrice: 0,
        moq: 0,
        batchSize: 0,
        stockCost: 0.5,
        backlogCost: 5,
        missedSaleCost: 0,
        visibleWeeks: 1,
        startOrders: 6,
        ordertime: 1,
        leadtime: 3
      },
      "200": {
        type: "warehouse",
        name: "Distribution Center",
        icon: "call-split",
        id: "200",
        supplier_id: "300",
        client_id: "1",
        validatedOrder: false,
        stock: 12,
        backlog: 0,
        cost: 0,
        revenue: 0,
        allowBacklog: true,
        unitPrice: 0,
        moq: 0,
        batchSize: 0,
        stockCost: 0.5,
        backlogCost: 2,
        missedSaleCost: 0,
        visibleWeeks: 1,
        startOrders: 6,
        ordertime: 1,
        leadtime: 3
      },
      "300": {
        type: "warehouse",
        name: "Factory",
        icon: "factory",
        id: "300",
        supplier_id: "400",
        client_id: "200",
        validatedOrder: false,
        stock: 12,
        backlog: 0,
        cost: 0,
        revenue: 0,
        allowBacklog: true,
        unitPrice: 0,
        moq: 0,
        batchSize: 0,
        stockCost: 0.5,
        backlogCost: 2,
        missedSaleCost: 0,
        visibleWeeks: 1,
        startOrders: 6,
        ordertime: 1,
        leadtime: 3
      },
      "400": {
        type: "factory",
        name: "Ingredients Supplier",
        icon: "web",
        id: "400",
        client_id: "300",
        validatedOrder: false,
        stock: 12,
        backlog: 0,
        cost: 0,
        revenue: 0,
        allowBacklog: true,
        unitPrice: 0,
        moq: 0,
        batchSize: 10,
        stockCost: 0.5,
        backlogCost: 2,
        missedSaleCost: 0,
        visibleWeeks: 1,
        startOrders: 6,
        ordertime: 1,
        leadtime: 3
      }
    }
  }
};
