import defaultBgTemplate from "@/assets/create/templates/defaultBgTemplate";
import defaultLeanDotsTemplate from "@/assets/create/templates/defaultLeanDotsTemplate";
import transparentBgTemplate from "@/assets/create/templates/transparentBgTemplate";
import aircraftTemplate from "@/assets/create/templates/aircraftTemplate";
import optimAircraftTemplate from "@/assets/create/templates/optimAircraftTemplate";
import smartphoneTemplate from "@/assets/create/templates/smartphoneTemplate";
import vaccineTemplate from "@/assets/create/templates/vaccineTemplate";
import headphonesTemplate from "@/assets/create/templates/headphonesTemplate";
import shortBgTemplate from "@/assets/create/templates/shortBgTemplate";
import shortLeanTemplate from "@/assets/create/templates/shortLeanDotsTemplate";
import shortLeanTemplateRound2 from "@/assets/create/templates/shortLeanDotsTemplateRound2";

import firebase from "@/firebaseConfig";
import { firestoreAction } from "vuexfire";

export default {
  state: {
    defaultTemplates: [
      defaultBgTemplate,
      transparentBgTemplate,
      shortBgTemplate,
      smartphoneTemplate,
      vaccineTemplate,
      headphonesTemplate,
      aircraftTemplate,
      optimAircraftTemplate,
      shortLeanTemplate,
      shortLeanTemplateRound2,
      defaultLeanDotsTemplate
    ],
    userTemplates: []
  },
  getters: {
    defaultTemplates: state => {
      return state.defaultTemplates;
    },
    userTemplates: state => {
      return state.userTemplates;
    },
    allTemplates: (state, getters) => {
      return [
        ...getters.defaultTemplates,
        ...getters.userTemplates,
        ...getters.teamTemplates
      ];
    }
  },
  mutations: {},
  actions: {
    bindUserTemplatesList: firestoreAction((context, payload) => {
      context.commit("setLoading", true);
      return context
        .bindFirestoreRef(
          "userTemplates",
          firebase
            .firestore()
            .collection("templates")
            .where("creator", "==", payload.uid)
        )
        .then(() => {
          context.commit("setLoading", false);
        });
    }),
    createTemplate({ commit, getters }, tempObj) {
      commit("setLoading", true);
      commit("clearError");
      const timestamp = firebase.firestore.FieldValue.serverTimestamp();
      return firebase
        .firestore()
        .collection("templates")
        .add({
          ...tempObj,
          created_at: timestamp,
          id: "temp-temp-tbupdated",
          creator: getters.currentUser.uid
        })
        .then(temp => {
          commit("setLoading", false);
          return { id: temp.id, ...tempObj };
        })
        .catch(error => {
          commit("setLoading", false);
          commit("setError", error);
          return error;
        });
    },
    deleteTemplate({ commit }, temp) {
      commit("setLoading", true);
      commit("clearError");
      return firebase
        .firestore()
        .collection("templates")
        .doc(temp.id)
        .delete()
        .then(() => {
          commit("setLoading", false);
          return temp;
        })
        .catch(error => {
          commit("setLoading", false);
          commit("setError", error);
        });
    },
    updateTemplate({ commit }, tempObj) {
      commit("setLoading", true);
      commit("clearError");
      return firebase
        .firestore()
        .collection("templates")
        .doc(tempObj.tid)
        .update({
          ...tempObj.obj
        })
        .then(() => {
          commit("setLoading", false);
          return tempObj;
        })
        .catch(error => {
          commit("setLoading", false);
          commit("setError", error);
        });
    },
    createDemoGame({ commit, dispatch }, demoObj) {
      commit("setLoading", true);
      commit("clearError");
      let createDemoFunction = firebase
        .functions()
        .httpsCallable("createDemoGame");
      return dispatch("verifyEmail", {
        email: demoObj.email,
        reason: "demo"
      })
        .then(() => {
          let creaGame = createDemoFunction({
            ...demoObj.obj
          });
          return creaGame;
        })
        .then(obj => {
          dispatch("identifyProspect", {
            email: demoObj.email,
            traits: {
              profile_type: demoObj.profileType
            },
            event_name: "play_demo",
            event_data: {
              roleName: obj.data.facilities[demoObj.roleId].name,
              roleId: demoObj.roleId,
              game_id: obj.data.id,
              name: obj.data.name,
              type: obj.data.type
            }
          });
          return dispatch("joinGame", {
            id: obj.data.id,
            name: obj.data.name,
            roleId: demoObj.roleId,
            roleName: obj.data.facilities[demoObj.roleId].name
          });
        })
        .catch(error => {
          commit("setLoading", false);
          commit("setError", error);
        });
    }
  }
};
