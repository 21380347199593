export default {
  title: "Short Beer Game",
  id: "shortbg",
  icon: "fast-forward",
  description: "12 weeks duration, lead times of 2 weeks",
  type: "beergame",
  config: {
    params: {
      totalWeeks: 12,
      transparent: false,
      timer: 60000,
      playTimer: false,
      product: "beer",
      timeUnit: "week",
      consumerDemand: [4, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8],
      configMessages: {
        allowChat: false,
        backlogMessage: {
          sentTo: [],
          active: true,
          message:
            "Watch out, you didn't have enough stock to fulfill the demand !",
          moreThan: 0
        },
        stockMessage: {
          sentTo: [],
          active: true,
          message:
            "Try to keep a reasonable amount of stock as it impacts your costs !",
          moreThan: 50
        },
        scheduleList: [
          {
            applyWeek: 0,
            message:
              "It's time to send your Order! Decide the quantity based on the previous demand, your stock and future planned deliveries.",
            to: ["all"]
          }
          // {
          //   applyWeek: 5,
          //   message:
          //     "The marketing team seems to have started a promotion, demand has recently increased !",
          //   to: ["1"]
          // }
        ]
      },
      currency: "$",
      debriefContent: [
        "Global Results",
        "Fill Rate",
        "Facility Charts",
        "Orders/Stock Charts",
        "Variability",
        "Lead times",
        "To Go Further"
      ]
    },
    facilities: {
      "1": {
        type: "shop",
        name: "retailer",
        icon: "cart",
        id: "1",
        supplier_id: "200",
        validatedOrder: false,
        stock: 8,
        backlog: 0,
        cost: 0,
        revenue: 0,
        allowBacklog: true,
        unitPrice: 0,
        moq: 0,
        batchSize: 0,
        stockCost: 0.5,
        backlogCost: 1,
        missedSaleCost: 0,
        visibleWeeks: 1,
        startOrders: 4,
        ordertime: 1,
        leadtime: 2
      },
      "200": {
        type: "warehouse",
        name: "wholesaler",
        icon: "domain",
        id: "200",
        supplier_id: "300",
        client_id: "1",
        validatedOrder: false,
        stock: 8,
        backlog: 0,
        cost: 0,
        revenue: 0,
        allowBacklog: true,
        unitPrice: 0,
        moq: 0,
        batchSize: 0,
        stockCost: 0.5,
        backlogCost: 1,
        missedSaleCost: 0,
        visibleWeeks: 1,
        startOrders: 4,
        ordertime: 1,
        leadtime: 2
      },
      "300": {
        type: "warehouse",
        name: "distributor",
        icon: "call-split",
        id: "300",
        supplier_id: "400",
        client_id: "200",
        validatedOrder: false,
        stock: 8,
        backlog: 0,
        cost: 0,
        revenue: 0,
        allowBacklog: true,
        unitPrice: 0,
        moq: 0,
        batchSize: 0,
        stockCost: 0.5,
        backlogCost: 1,
        missedSaleCost: 0,
        visibleWeeks: 1,
        startOrders: 4,
        ordertime: 1,
        leadtime: 2
      },
      "400": {
        type: "factory",
        name: "manufacturer",
        icon: "factory",
        id: "400",
        client_id: "300",
        validatedOrder: false,
        stock: 8,
        backlog: 0,
        cost: 0,
        revenue: 0,
        allowBacklog: true,
        unitPrice: 0,
        moq: 0,
        batchSize: 0,
        stockCost: 0.5,
        backlogCost: 1,
        missedSaleCost: 0,
        visibleWeeks: 1,
        startOrders: 4,
        ordertime: 1,
        leadtime: 2
      }
    }
  }
};
