<template>
  <footer class="footer-wrapper footer has-background-dark">
    <div class="content has-text-centered has-text-white">
      <!-- <p>Made from Paris by Mathias Le Scaon</p> -->
      <nav
        class="breadcrumb has-bullet-separator is-centered"
        aria-label="breadcrumbs"
      >
        <ul>
          <li>
            <a href="mailto:hello@zensimu.com" class="has-text-primary"
              >hello@zensimu.com</a
            >
          </li>
          <li v-if="!portalLook">
            <a
              href="https://www.linkedin.com/company/zensimu/"
              class="has-text-primary"
              target="_blank"
              rel="noreferrer"
              ><b-icon size="is-small" icon="linkedin-box"></b-icon> Connect
            </a>
          </li>
          <li>
            <a
              href="https://zensimu.com/terms"
              class="has-text-primary"
              target="_blank"
              rel="noreferrer"
              >Terms of Use
            </a>
          </li>
          <li>
            <a
              href="https://zensimu.com/privacy"
              class="has-text-primary"
              target="_blank"
              rel="noreferrer"
              >Privacy Policy
            </a>
          </li>
        </ul>
      </nav>
      <p></p>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  computed: {
    portalLook() {
      return this.$store.getters.portalLook;
    }
  }
};
</script>

<style lang="scss">
.footer-wrapper {
  margin: 0;
  overflow-x: hidden;
  nav ul li + li {
    margin-top: 0;
  }
  .dashPage {
    width: 0;
    opacity: 0;
  }
  #HW_badge_cont {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    text-align: right;
    #HW_badge {
      display: block;
      margin-top: -4px;
      left: 95%;
    }
  }
}
</style>
