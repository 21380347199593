/* global analytics */
// import LogRocket from "logrocket";

// function getCookie(name) {
//   var v = document.cookie.match("(^|;) ?" + name + "=([^;]*)(;|$)");
//   return v ? v[2] : null;
// }

// import axios from "axios";
export default {
  state: {
    loading: false,
    error: null,
    selectSignUpPlan: false,
    attributionChoices: [
      { text: "Search engine (Google, Bing..)", val: "search" },
      { text: "Friend or colleague", val: "friend" },
      { text: "On social media", val: "social" },
      { text: "Online ads", val: "ads" },
      { text: "In the news", val: "news" },
      { text: "In a class exercise", val: "class" },
      { text: "Other", val: "other" }
    ],
    gameTypes: {
      beergame: {
        name: "Beer Game",
        type: "beergame",
        logo: require("@/assets/logos/beergame-round.svg"),
        classColor: "is-primary"
      },
      leangame: {
        name: "Lean Game",
        type: "leangame",
        logo: require("@/assets/logos/leangame-round.svg"),
        classColor: "is-success",
        articleLink:
          "https://docs.zensimu.com/article/18-what-is-the-lean-game",
        articleId: "61dd5c05140013074137c092"
      }
    }
  },
  getters: {
    attributionChoices: state => state.attributionChoices,
    gameTypes: state => state.gameTypes,
    showError: state => {
      return state.error !== undefined && state.error !== null;
    },
    loading: state => {
      return state.loading;
    },
    error: state => {
      return state.error;
    },
    zensimuSite: () => {
      // return true;
      return (
        window &&
        window.location.hostname &&
        window.location.hostname.includes("zensimu")
      );
    },
    onboardingSteps: (state, getters) => {
      let obj = {
        show: false,
        toDo: 4,
        done: 0
      };
      if (getters.currentUser && getters.currentUser.email) {
        if (getters.boundUserGamesList) {
          obj.show = true;
        }
        obj.hasCreated = getters.userGamesList.length;
        obj.finishedGames = getters.userGamesList.filter(game => game.finished);
        obj.notFinishedGame = getters.userGamesList.find(
          game => !game.finished
        );
        obj.hasPlan =
          getters.teamAccount &&
          getters.teamAccount.plans &&
          Object.values(getters.teamAccount.plans).find(tp => tp.active)
            ? getters.teamAccount.plans
            : null;

        obj.hasSharedGames = getters.userGamesList.some(game => {
          let foundNames = Object.values(game.facilities).filter(
            faci => faci.playerName || faci.playerName === ""
          );
          return (
            (game.players && game.players.length > 1) || foundNames.length > 1
          );
        });
        obj.done =
          (obj.hasCreated ? 1 : 0) +
          (obj.finishedGames && obj.finishedGames.length ? 1 : 0) +
          (obj.hasPlan ? 1 : 0) +
          (obj.hasSharedGames ? 1 : 0);
        if (obj.done >= obj.toDo) {
          obj.show = false;
        }
      }
      return obj;
    }
  },
  mutations: {
    setError(state, payload) {
      state.error = payload;
    },
    clearError(state) {
      state.error = null;
    },
    setLoading(state, payload) {
      state.loading = payload;
    }
  },
  actions: {
    identifyUser({ getters, dispatch }, userObj) {
      if (userObj.email && userObj.uid) {
        if (!getters.currentUser) {
          return dispatch("updateUserProfile", {
            email: userObj.email,
            uid: userObj.uid
          }).then(() => dispatch("identifyUser", userObj));
        }
        let maxPlan = null;
        let joinPlans = "";
        if (getters.teamAccount && getters.teamAccount.plans) {
          Object.entries(getters.teamAccount.plans).forEach(([gtype, pp]) => {
            if (
              pp.active &&
              pp.until &&
              (!maxPlan ||
                (maxPlan.until && maxPlan.until.seconds < pp.until.seconds))
            ) {
              maxPlan = pp;
            }
            if (pp.active) {
              joinPlans += (joinPlans.length > 0 ? "," : "") + gtype;
            }
          });
        }
        let identObj = {
          name: userObj.name || userObj.displayName,
          email: userObj.email,
          profile_type: getters.currentUser.profileType,
          ...(getters.currentUser.firstname && {
            firstname: getters.currentUser.firstname
          }),
          ...(getters.currentUser.lastname && {
            lastname: getters.currentUser.lastname
          }),
          ...(getters.currentUser.attributionSource && {
            attribution_source: getters.currentUser.attributionSource,
            attribution_othertext: getters.currentUser.attributionOtherText
          }),
          ...(getters.teamAccount && {
            team_name: getters.teamAccount.name,
            team_role:
              getters.teamAccount.members &&
              getters.teamAccount.members[userObj.uid]
                ? getters.teamAccount.members[userObj.uid].role
                : null,
            plan_active: maxPlan ? maxPlan.active : null,
            plan_duration: maxPlan ? maxPlan.duration : null,
            plan_until:
              maxPlan && maxPlan.until
                ? new Date(maxPlan.until.seconds * 1000)
                    .toISOString()
                    .slice(0, 10)
                : null,
            plan_games: joinPlans,
            plan_name: maxPlan ? maxPlan.planName : null
          })
        };
        if (analytics) {
          analytics.identify(userObj.uid, identObj);
        }
        if (window.Beacon) {
          window.Beacon("identify", { ...identObj, uid: userObj.uid });
        }
        // if (LogRocket && window && window.location.hostname !== "localhost") {
        //   LogRocket.init("dzvo0d/zensimu");
        //   LogRocket.identify(userObj.uid, identObj);
        // }
      }
    },
    identifyProspect(context, payload) {
      if (payload.email) {
        analytics.identify(payload.email, {
          email: payload.email,
          ...payload.traits
        });
        if (payload.event_name && payload.event_data) {
          analytics.track(payload.event_name, payload.event_data);
        }
      }
    },
    addScript() {
      setTimeout(() => {
        let s = document.createElement("script");
        s.src = "https://js.stripe.com/v3";
        document.body.appendChild(s);
      }, 100);
      // setTimeout(() => {
      //   let s = document.createElement("script");
      //   s.type = "text/javascript";
      //   s.async = true;
      //   s.defer = true;
      //   s.src = "//cdn.headwayapp.co/widget.js";
      //   document.body.appendChild(s);
      // }, 200);
      setTimeout(() => {
        let s = document.createElement("script");
        s.type = "text/javascript";
        s.async = true;
        s.defer = true;
        s.src =
          "https://cdn.jsdelivr.net/npm/cookieconsent@3/build/cookieconsent.min.js";
        document.body.appendChild(s);
      }, 100);
      setTimeout(() => {
        if (window.cookieconsent) {
          window.cookieconsent.initialise({
            palette: {
              popup: {
                background: "#ffbcb9",
                text: "#333"
              },
              button: {
                background: "#fff",
                text: "#333"
              }
            },
            position: "bottom-right",
            content: {
              message:
                "This website uses cookies to improve your browsing experience.",
              dismiss: "Continue",
              link: "Learn more",
              href: "https://zensimu.com/privacy"
            }
          });
        }
      }, 200);
    }
  }
};
