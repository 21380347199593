var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"bg-navbar"}},[(_vm.showNavbar)?_c('b-navbar',{style:({
      'background-color': _vm.custoLook.bckColor,
      color: _vm.custoLook.textColor,
      'border-top':
        _vm.custoLook.btnBestColor.toLowerCase() !==
        _vm.custoLook.bckColor.toLowerCase()
          ? ("3px solid " + (_vm.custoLook.btnBestColor))
          : 'none'
    }),attrs:{"fixed-top":"","transparent":"","wrapper-class":"container","aria-label":"main navigation"}},[_c('template',{slot:"brand"},[_c('b-navbar-item',{attrs:{"tag":"router-link","to":_vm.navbarLogoLink}},[_c('main-logo',{attrs:{"custo-look":_vm.custoLook,"show-small-title":!_vm.$route.meta.gamePage && !_vm.$route.meta.userPage}})],1)],1),_c('template',{slot:"start"},[_c('b-navbar-item',{staticClass:"breadcrumb",attrs:{"tag":"nav","aria-label":"breadcrumbs"}},[_c('ul',_vm._l((_vm.layout.start.links),function(link){return _c('li',{key:link.label,style:({
              color: _vm.custoLook.textColor
            })},[_c('router-link',{style:({
                color: _vm.custoLook.textColor
              }),attrs:{"active-class":"is-active","to":link.path}},[_vm._v(_vm._s(link.label))])],1)}),0)])],1),_c('template',{slot:"end"},[_vm._l((_vm.layout.end.links),function(link){return _c('b-navbar-item',{key:link.label,staticClass:"end-tabs",class:{ 'is-disablink': link.disab },style:({
          color: _vm.custoLook.textColor
        }),attrs:{"tag":link.disab ? 'span' : 'router-link',"to":link.path,"active-class":"is-active"}},[_c('b-tooltip',{attrs:{"type":"is-dark","active":link.disab ? true : false,"label":link.disab ? link.disabMessage : link.message ? link.message : '',"position":"is-bottom","size":"is-small","multilined":""}},[_c('span',{staticClass:"bg-nav-text"},[(link.icon)?_c('b-icon',{staticClass:"mr-1",attrs:{"icon":link.icon}}):_vm._e(),_vm._v(" "+_vm._s(link.label)+" ")],1)])],1)}),_vm._l((_vm.layout.end.tags),function(tag){return _c('b-navbar-item',{key:("" + (tag.label) + (tag.type)),class:{ 'is-disablink': tag.disab },style:({
          color: _vm.custoLook.textColor
        }),attrs:{"tag":tag.disab ? 'span' : 'router-link',"to":tag.path,"active-class":"is-active"}},[_c('b-tooltip',{attrs:{"type":"is-dark","active":tag.message ? true : false,"label":tag.message ? tag.message : '',"position":"is-bottom","size":"is-small","multilined":""}},[_c('b-tag',{staticClass:"bg-nav-text",class:{ 'has-logo': tag.logo ? true : false },attrs:{"type":tag.type,"rounded":"","size":"is-medium"}},[(tag.logo)?_c('figure',{staticClass:"mr-2 image is-24x24 is-inline-block"},[_c('img',{attrs:{"src":tag.logo}})]):_vm._e(),_c('span',{staticClass:"is-inline-block"},[_vm._v(_vm._s(tag.label))])])],1)],1)}),_vm._l((_vm.layout.end.dropdowns),function(dropdown){return _c('b-navbar-dropdown',{key:dropdown.label,attrs:{"arrowless":"","right":"","collapsible":""}},[_c('template',{slot:"label"},[(dropdown.icon)?_c('b-icon',{staticClass:"mr-1 ",style:({
              color: _vm.custoLook.textColor
            }),attrs:{"icon":dropdown.icon}}):_vm._e(),_c('span',{style:({
              color: _vm.custoLook.textColor
            })},[_vm._v(" "+_vm._s(dropdown.label)+" ")]),_c('b-icon',{style:({
              color: _vm.custoLook.textColor
            }),attrs:{"icon":"chevron-down"}})],1),_vm._l((dropdown.links),function(link){return _c('b-navbar-item',{key:link.label,class:link.classes,attrs:{"tag":link.path ? 'router-link' : 'a',"to":link.path,"active-class":"is-active"},on:{"click":function($event){link.action ? _vm.launchAction(link.action) : true}}},[(link.icon)?_c('b-icon',{staticClass:"mr-1",attrs:{"icon":link.icon,"size":"is-small"}}):_vm._e(),_vm._v(" "+_vm._s(link.label)+" ")],1)})],2)}),_c('b-navbar-item',{staticClass:"bg-buttons",attrs:{"tag":"div"}},[_c('div',{staticClass:"buttons"},_vm._l((_vm.layout.end.buttons),function(btn){return _c('router-link',{key:btn.label,staticClass:"button is-rounded",class:btn.classes,attrs:{"to":btn.path}},[_vm._v(" "+_vm._s(btn.label)+" ")])}),1)])],2)],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }