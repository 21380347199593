<template>
  <div id="bg-navbar">
    <b-navbar
      fixed-top
      transparent
      wrapper-class="container"
      v-if="showNavbar"
      :style="{
        'background-color': custoLook.bckColor,
        color: custoLook.textColor,
        'border-top':
          custoLook.btnBestColor.toLowerCase() !==
          custoLook.bckColor.toLowerCase()
            ? `3px solid ${custoLook.btnBestColor}`
            : 'none'
      }"
      aria-label="main navigation"
    >
      <template slot="brand">
        <b-navbar-item tag="router-link" :to="navbarLogoLink">
          <main-logo
            :custo-look="custoLook"
            :show-small-title="!$route.meta.gamePage && !$route.meta.userPage"
          ></main-logo>
        </b-navbar-item>
      </template>
      <template slot="start">
        <b-navbar-item tag="nav" class="breadcrumb" aria-label="breadcrumbs">
          <ul>
            <li
              v-for="link in layout.start.links"
              v-bind:key="link.label"
              :style="{
                color: custoLook.textColor
              }"
            >
              <router-link
                active-class="is-active"
                :style="{
                  color: custoLook.textColor
                }"
                :to="link.path"
                >{{ link.label }}</router-link
              >
            </li>
          </ul>
        </b-navbar-item>
      </template>
      <template slot="end">
        <b-navbar-item
          v-for="link in layout.end.links"
          v-bind:key="link.label"
          :tag="link.disab ? 'span' : 'router-link'"
          class="end-tabs"
          :to="link.path"
          :style="{
            color: custoLook.textColor
          }"
          :class="{ 'is-disablink': link.disab }"
          active-class="is-active"
        >
          <b-tooltip
            type="is-dark"
            :active="link.disab ? true : false"
            :label="
              link.disab ? link.disabMessage : link.message ? link.message : ''
            "
            position="is-bottom"
            size="is-small"
            multilined
          >
            <span class="bg-nav-text">
              <b-icon v-if="link.icon" :icon="link.icon" class="mr-1"></b-icon>
              {{ link.label }}
            </span>
          </b-tooltip>
        </b-navbar-item>
        <b-navbar-item
          v-for="tag in layout.end.tags"
          v-bind:key="`${tag.label}${tag.type}`"
          :tag="tag.disab ? 'span' : 'router-link'"
          :to="tag.path"
          :style="{
            color: custoLook.textColor
          }"
          :class="{ 'is-disablink': tag.disab }"
          active-class="is-active"
        >
          <b-tooltip
            type="is-dark"
            :active="tag.message ? true : false"
            :label="tag.message ? tag.message : ''"
            position="is-bottom"
            size="is-small"
            multilined
          >
            <b-tag
              class="bg-nav-text"
              :type="tag.type"
              rounded
              size="is-medium"
              :class="{ 'has-logo': tag.logo ? true : false }"
            >
              <figure
                v-if="tag.logo"
                class="mr-2 image is-24x24 is-inline-block"
              >
                <img :src="tag.logo" />
              </figure>
              <span class="is-inline-block">{{ tag.label }}</span>
            </b-tag>
          </b-tooltip>
        </b-navbar-item>
        <b-navbar-dropdown
          arrowless
          right
          collapsible
          v-for="dropdown in layout.end.dropdowns"
          v-bind:key="dropdown.label"
        >
          <template slot="label">
            <b-icon
              v-if="dropdown.icon"
              :icon="dropdown.icon"
              class="mr-1 "
              :style="{
                color: custoLook.textColor
              }"
            ></b-icon>
            <span
              :style="{
                color: custoLook.textColor
              }"
            >
              {{ dropdown.label }}
            </span>
            <b-icon
              icon="chevron-down"
              :style="{
                color: custoLook.textColor
              }"
            ></b-icon>
          </template>
          <b-navbar-item
            v-for="link in dropdown.links"
            v-bind:key="link.label"
            :tag="link.path ? 'router-link' : 'a'"
            :to="link.path"
            :class="link.classes"
            @click="link.action ? launchAction(link.action) : true"
            active-class="is-active"
          >
            <b-icon
              v-if="link.icon"
              :icon="link.icon"
              size="is-small"
              class="mr-1"
            ></b-icon>
            {{ link.label }}
          </b-navbar-item>
        </b-navbar-dropdown>
        <b-navbar-item tag="div" class="bg-buttons">
          <div class="buttons">
            <router-link
              class="button is-rounded"
              v-for="btn in layout.end.buttons"
              v-bind:key="btn.label"
              :to="btn.path"
              :class="btn.classes"
            >
              {{ btn.label }}
            </router-link>
          </div>
        </b-navbar-item>
      </template>
    </b-navbar>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import MainLogo from "@/components/MainLogo.vue";
export default {
  name: "navbar",
  components: { MainLogo },
  methods: {
    launchAction: function(action) {
      if (action === "quitgame") {
        this.$store
          .dispatch("quitGame", this.currentUser.playingGame)
          .then(() => {
            if (this.$route.name == "play") {
              this.$router.push("/game/" + this.currentGame.id);
            }
          });
      } else if (action === "logout") {
        this.$store.dispatch("logout").then(() => {
          if (this.$route.name == "play") {
            this.$router.push("/game/" + this.currentGame.id);
          }
        });
      }
    }
  },
  computed: {
    ...mapGetters([
      "loading",
      "showNavbar",
      "onboardingSteps",
      "currentGame",
      "currentUser",
      "custoLook",
      "currentWorkspace",
      "portalLook",
      "authUser",
      "availGames",
      "gameTypes"
    ]),
    navbarLogoLink() {
      if (this.authUser && this.authUser.email) {
        return "/dashboard/games";
      } else if (this.currentGame && this.$route.meta.gamePage) {
        return this.currentGame.creator
          ? `/user/${this.currentGame.creator}`
          : `/game/${this.currentGame.id}`;
      } else if (this.$route.meta.userPage) {
        return `/user/${this.$route.params.uid}`;
      } else {
        return "/";
      }
    },
    layout() {
      let obj = {
        start: { links: [], dropdowns: [] },
        end: { links: [], tags: [], dropdowns: [], buttons: [] }
      };
      let workspaceName =
        this.currentWorkspace && this.currentWorkspace.pageName
          ? this.currentWorkspace.pageName
          : "All Games";
      workspaceName = workspaceName.replace("My Instructor Page", "All Games");
      if (this.$route.meta.gamePage && this.currentGame) {
        let myName = `Game: ${this.currentGame.name}`;
        if (this.currentWorkspace && this.currentWorkspace.activePage) {
          obj.start.links.push({
            label: workspaceName,
            path: `/user/${this.currentWorkspace.id}`
          });
        }
        obj.start.links.push({
          label: myName,
          path: `/game/${this.currentGame.id}/intro`
        });
        obj.end.links.push({
          label: "Intro",
          path: `/game/${this.currentGame.id}/intro`
        });
        obj.end.links.push({
          label: "Play",
          path: `/game/${this.currentGame.id}/play`,
          disab:
            !this.$route.meta.stagePage &&
            !this.currentGame.finished &&
            (!this.currentUser ||
              !this.currentUser.playingGame ||
              this.currentUser.playingGame.id !== this.currentGame.id),
          disabMessage: "Connect to the game using one of the buttons below"
        });
        obj.end.links.push({
          label: "Results",
          path: `/game/${this.currentGame.id}/results`,
          disab: !this.currentGame.finished,
          disabMessage:
            "The results will be available after the game is finished"
        });
      }
      if (this.$route.meta.stagePage) {
        if (
          this.currentUser &&
          this.currentUser.playingGame &&
          this.currentUser.playingGame.id === this.currentGame.id
        ) {
          obj.start.links.push({
            label: `Playing as: ${this.currentUser.playingGame.roleName}`,
            path: `/game/${this.currentGame.id}/play`
          });
        } else if (this.currentGame) {
          obj.start.links.push({
            label: `Playing as: Observer`,
            path: `/game/${this.currentGame.id}/play`
          });
        }
      }
      if (this.$route.meta.userPage) {
        obj.start.links.push({
          label: workspaceName,
          path: `/user/${this.$route.params.uid}`
        });
      }
      if (
        this.$route.name === "home" &&
        !this.authUser &&
        !this.$route.meta.loginPage
      ) {
        obj.end.buttons.push({
          label: "Log In",
          path: "/login",
          classes: { "is-info": true, "is-small": true }
        });
        if (!this.portalLook) {
          obj.end.buttons.push({
            label: "Sign Up",
            path: "/signup",
            classes: { "is-primary": true, "is-small": true }
          });
        }
      }
      if (this.authUser && this.currentUser) {
        let ddown = {
          icon: "account-circle",
          label: this.currentUser.firstname
            ? this.currentUser.firstname
            : this.currentUser.name
            ? this.currentUser.name
            : this.currentUser.playerName,
          links: []
        };
        if (this.currentUser.playingGame) {
          ddown.links.push({
            icon: "gamepad-square",
            label: `Playing '${this.currentUser.playingGame.name}' as '${this.currentUser.playingGame.roleName}'`,
            path: `/game/${this.currentUser.playingGame.id}/play`,
            classes: { "has-text-info": true }
          });
          ddown.links.push({
            label: "Quit Game",
            action: this.authUser.email ? "quitgame" : "logout",
            icon: "cancel",
            classes: { "has-text-danger": true }
          });
        }
        if (this.authUser.email) {
          if (!this.$route.meta.gamePage && !this.portalLook) {
            // Status buttons (not shown when on a game page to not surcharge the UI)
            if (this.onboardingSteps.show) {
              obj.end.tags.push({
                label: `Get Started ${this.onboardingSteps.done}/${this.onboardingSteps.toDo}`,
                path: `/dashboard/intro`,
                type: "is-info is-light",
                message: "A few steps to help you become a game master."
              });
            }
            Object.entries(this.availGames).forEach(([agtype, agval]) => {
              let playersLabel =
                agval.unlimitedPlayers || !agval.maxPlayers
                  ? "Unlimited"
                  : `Players ${
                      agval.players && agval.players.length
                        ? agval.players.length
                        : 0
                    }/${agval.maxPlayers}`;
              let planLabel = agval.planName
                ? `Your ${this.gameTypes[agtype].name} plan: ${agval.planName}`
                : `View your ${this.gameTypes[agtype].name} plan`;
              obj.end.tags.push({
                logo: this.gameTypes[agtype].logo,
                label: playersLabel,
                path: `/dashboard/subscription`,
                type: `${this.gameTypes[agtype].classColor} is-light`,
                message: planLabel
              });
            });
          }

          ddown.links.push({
            label: "My Games",
            icon: "view-dashboard",
            path: "/dashboard/games"
          });
          ddown.links.push({
            label: "Profile Settings",
            icon: "account",
            path: "/dashboard/profile"
          });
          ddown.links.push({
            label: "Log out",
            action: "logout",
            icon: "logout",
            classes: { "has-text-danger": true }
          });
        }

        obj.end.dropdowns.push(ddown);
      }
      return obj;
    }
  },
  data() {
    return {
      isBurgerActive: false
    };
  }
};
</script>

<style lang="scss" scoped>
#bg-navbar .navbar {
  .tag.has-logo figure.image {
    margin-left: -6px;
    padding-top: 7px;
  }
  .tag.has-logo span.is-inline-block {
    padding-bottom: 8px;
    font-size: 0.9em;
  }
  .navbar-item {
    padding-right: 1em;
    .nav-span-item,
    span {
      @media (max-width: 1023px) {
        color: hsl(0, 0%, 21%) !important;
      }
    }
  }
  .navbar-item.breadcrumb {
    a {
      opacity: 0.6 !important;
    }
    a.router-link-exact-active,
    a:hover {
      opacity: 1 !important;
    }
  }
  .navbar-item.end-tabs {
    &:hover,
    &.is-active {
      backdrop-filter: brightness(92%);
    }
    &.is-disablink {
      backdrop-filter: none;
      cursor: not-allowed;
      .bg-nav-text {
        opacity: 0.6;
      }
    }
  }
  .navbar-brand {
    .navbar-item {
      padding-top: 0.2rem;
      padding-bottom: 0.3rem;
    }
  }
  .navbar-item.has-dropdown:hover {
    background: none !important;
  }
  .navbar-brand .navbar-item:hover,
  .bg-buttons:hover {
    backdrop-filter: none;
  }
  .navbar-item.bg-transparent {
    backdrop-filter: none !important;
  }
  .navbar-item.bgnav-logo {
    padding-top: 0;
    padding-bottom: 0;
  }
  .dropdown-item {
    outline: none;
  }
  .dropdown-item a b {
    color: $success;
  }
  .nav-span-item {
    margin-right: 6px;
  }
}
</style>
