<template>
  <div class="logo-wrapper" :class="{ inline: !showTitle, noclick: noclick }">
    <zensimu-logo
      v-if="zensimuSite && (!custoLook || !custoLook.logoUrl)"
      :logo-height="showTitle ? 40 : 21"
      :show-title="showSmallTitle"
      class="mt-1"
    ></zensimu-logo>
    <svg
      v-if="!zensimuSite && (!custoLook || !custoLook.logoUrl)"
      viewBox="0 0 96 96"
      :style="{ height: logoHeight }"
    >
      <path
        class="logo-bck"
        d="M48,96C21.533,96,0,74.467,0,48C0,21.53,21.533,0,48,0c26.467,0,48,21.53,48,48C96,74.467,74.467,96,48,96z"
      />
      <g transform="scale(40, -40)">
        <path
          fill="#FFFFFF"
          d="M1.514-1.85c-0.062,0-0.119,0.033-0.169,0.097c-0.036,0.046-0.07,0.11-0.108,0.201
  		C1.198-1.457,1.16-1.345,1.123-1.236c-0.037,0.107-0.074,0.217-0.11,0.305C0.983-0.861,0.957-0.81,0.934-0.78
  		C0.911-0.81,0.885-0.861,0.856-0.931c-0.037-0.088-0.074-0.198-0.11-0.304C0.729-1.284,0.713-1.331,0.697-1.376
  		c-0.02-0.056-0.081-0.085-0.137-0.066c-0.056,0.02-0.085,0.081-0.066,0.137C0.51-1.26,0.525-1.213,0.541-1.167
  		c0.038,0.111,0.076,0.224,0.116,0.319c0.038,0.091,0.072,0.155,0.108,0.201C0.814-0.583,0.871-0.55,0.934-0.55
  		c0.062,0,0.119-0.033,0.169-0.097c0.036-0.046,0.07-0.11,0.108-0.201C1.25-0.943,1.289-1.056,1.326-1.165
  		C1.362-1.272,1.4-1.381,1.436-1.469C1.465-1.539,1.492-1.59,1.514-1.62c0.023,0.03,0.049,0.081,0.078,0.151
  		C1.63-1.38,1.667-1.271,1.703-1.165c0.019,0.056,0.08,0.086,0.137,0.067c0.056-0.019,0.086-0.08,0.067-0.137
  		C1.869-1.344,1.831-1.457,1.792-1.552C1.754-1.643,1.719-1.707,1.684-1.753C1.634-1.817,1.577-1.85,1.514-1.85z"
        />
      </g>
    </svg>
    <div class="has-text-centered" v-if="custoLook && custoLook.logoUrl">
      <img
        :style="{ height: logoHeight }"
        class="is-inline-block"
        :src="custoLook.logoUrl"
      />
    </div>
    <!-- <h1
      v-if="showTitle && !zensimuSite"
      :style="{
        color: custoLook.textColor
      }"
    >
      <span v-if="custoLook && custoLook.appTitle">{{
        custoLook.appTitle
      }}</span>
      <span v-if="!zensimuSite && (!custoLook || !custoLook.appTitle)"
        >The Beergame App</span
      >
    </h1> -->
    <h2 v-if="showSmallTitle" :style="{ color: custoLook.textColor }">
      <span v-if="custoLook && custoLook.appTitle">{{
        custoLook.appTitle
      }}</span>
      <span v-if="!zensimuSite && (!custoLook || !custoLook.appTitle)"
        >The Beergame App
        <span class="has-text-grey has-text-weight-normal"
          >by Zensimu
        </span></span
      >
    </h2>
  </div>
</template>

<script>
import ZensimuLogo from "@/components/ZensimuLogo.vue";
import anime from "animejs";

export default {
  name: "main-logo",
  components: { ZensimuLogo },
  props: ["showTitle", "showSmallTitle", "logoUrl", "custoLook", "noclick"],
  mounted() {
    this.flatBorder(0);
  },
  methods: {
    roundBorder(dur) {
      anime({
        targets: ".logo-round-path",
        strokeDashoffset: [anime.setDashoffset, 0],
        easing: "easeOutQuad",
        duration: dur
      });
    },
    flatBorder(dur) {
      anime({
        targets: ".logo-round-path",
        strokeDashoffset: [0, anime.setDashoffset],
        easing: "easeOutQuad",
        duration: dur
      });
    }
  },
  computed: {
    zensimuSite() {
      return this.$store.getters.zensimuSite;
    },
    logoHeight() {
      if (this.showTitle) {
        return "60px";
      } else {
        return "32px";
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.logo-wrapper {
  display: inline-block;
  align-items: center;
  .logo-bck {
    fill: $theme-pink;
  }
  h1 {
    font-size: 2rem;
    font-weight: bold;
    padding-top: -0.5em;
    color: $grey-darker;
  }
  h2 {
    font-size: 1rem;
    font-weight: bold;
    padding-bottom: 4px;
    color: hsl(0, 0%, 21%);
    padding-left: 12px;
  }
}
.logo-wrapper:hover {
  .logo-bck {
    fill: #ff9e9a;
  }
  &.noclick .logo-bck {
    fill: $theme-pink;
  }
}
.logo-wrapper.inline {
  display: flex;
  svg {
    padding-top: 0px;
    padding-bottom: 2px;
  }
  img {
    padding-bottom: 0px;
    padding-top: 4px;
    max-height: none !important;
  }
}
</style>
