/* eslint-disable no-empty-character-class */
import Vue from "vue";
import App from "./App.vue";
import Buefy from "buefy";
import router from "./router";
import store from "./store";
import "@mdi/font/css/materialdesignicons.min.css";
import "./assets/main.scss";
import firebase from "@/firebaseConfig";
import Vuelidate from "vuelidate";
import Vue2Filters from "vue2-filters";
import { firestorePlugin } from "vuefire";
import VueMeta from "vue-meta";
import VueShepherd from "vue-shepherd";

Vue.prototype.$firebase = firebase;

Vue.config.productionTip = false;
Vue.use(Buefy);
Vue.use(VueShepherd);
Vue.use(Vuelidate);
Vue.use(firestorePlugin);
Vue.use(Vue2Filters, {
  currency: {
    symbol: "$",
    decimalDigits: 0,
    spaceBetweenAmountAndSymbol: true
  }
});
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
});

Vue.filter("minsec", function(millis) {
  let minutes = Math.max(0, Math.floor(millis / (60 * 1000)));
  let seconds = Math.max(
    0,
    Math.floor((10 * (millis - minutes * 60000)) / 1000) / 10
  );
  return (
    (minutes < 10 ? "0" + minutes : minutes) +
    ":" +
    (seconds < 10 ? "0" + seconds : seconds)
  );
});

new Vue({
  el: "#app",
  router,
  store,
  mixins: [Vue2Filters.mixin],
  render: h => h(App),
  mounted: () => {
    document.dispatchEvent(new Event("x-app-rendered"));
  }
}).$mount("#app");
