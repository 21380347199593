import firebase from "@/firebaseConfig";
import { ToastProgrammatic as Toast } from "buefy";
import { firestoreAction } from "vuexfire";

export default {
  state: {
    publicProfile: null,
    currentUser: null,
    teamAccount: null,
    startTrialPack: null
  },
  mutations: {
    setStartTrialPack(state, packName) {
      state.startTrialPack = packName;
    },
    removeUser(state) {
      state.currentUser = null;
      state.authUser = null;
    }
  },
  getters: {
    startTrialPack: state => {
      return state.startTrialPack;
    },
    teamAccount: state => {
      return state.teamAccount ? state.teamAccount[0] : null;
    },
    publicProfile: state => {
      return state.publicProfile;
    },
    availGames: (state, getters) => {
      let obj = {};
      if (
        getters.teamAccount &&
        getters.teamAccount.members[getters.currentUser.uid].games
      ) {
        getters.teamAccount.members[getters.currentUser.uid].games.forEach(
          gt => {
            if (
              getters.teamAccount.plans &&
              getters.teamAccount.plans[gt] &&
              getters.teamAccount.plans[gt].active
            ) {
              obj[gt] = getters.teamAccount.plans[gt];
            }
          }
        );
      }
      return obj;
    },
    currentUser: state => {
      return state.currentUser;
    }
  },
  actions: {
    updateUserAuth({ commit }, payload) {
      // return Promise.resolve();
      commit("setLoading", true);
      commit("clearError");
      const user = firebase.auth().currentUser;
      if (!user.email || !payload.oldPassword) {
        throw Promise.reject();
      }
      const credential = firebase.auth.EmailAuthProvider.credential(
        user.email,
        payload.oldPassword
      );
      return user
        .reauthenticateWithCredential(credential)
        .then(() => {
          let updatePromises = [];
          if (payload.email && payload.email !== user.email) {
            updatePromises.push(user.updateEmail(payload.email));
            updatePromises.push(
              firebase
                .firestore()
                .collection("users")
                .doc(user.uid)
                .set({ email: payload.email }, { merge: true })
            );
          } else if (payload.newPassword) {
            updatePromises.push(user.updatePassword(payload.newPassword));
          }
          return Promise.all(updatePromises).then(() => {
            commit("setLoading", false);
            return payload;
          });
        })
        .catch(error => {
          commit("setLoading", false);
          commit("setError", error);
          throw error;
        });
    },
    startTrial({ getters, commit }, packName) {
      if (getters.currentUser && getters.currentUser.email) {
        let startTrialFunction = firebase
          .functions()
          .httpsCallable("startTrial");
        commit("setLoading", true);
        return startTrialFunction({
          uid: getters.currentUser.uid,
          name: getters.currentUser.name
            ? getters.currentUser.name
            : `${getters.currentUser.firstname} ${getters.currentUser.lastname}`,
          pack: packName
        })
          .then(packResult => {
            commit("setStartTrialPack", null);
            commit("setLoading", false);
            Toast.open(
              `Your trial of the ${packResult.data.gameName} just started`
            );
            return true;
          })
          .catch(error => {
            commit("setStartTrialPack", null);
            commit("setLoading", false);
            setTimeout(() => commit("setError", error), 500);
            return true;
          });
      }
    },
    updateUserProfile({ commit }, payload) {
      commit("setLoading", true);
      commit("clearError");
      let updatePromises = [];
      let user = firebase.auth().currentUser;
      if (payload.name && user) {
        updatePromises.push(
          user.updateProfile({
            displayName: payload.name
          })
        );
      }
      updatePromises.push(
        firebase
          .firestore()
          .collection("users")
          .doc(payload.uid)
          .set({ ...payload }, { merge: true })
      );
      return Promise.all(updatePromises)
        .then(() => {
          commit("setLoading", false);
          return payload;
        })
        .catch(error => {
          commit("setLoading", false);
          commit("setError", error);
          return false;
        });
    },
    bindCurrentUser: firestoreAction((context, payload) => {
      return Promise.all([
        context.bindFirestoreRef(
          "currentUser",
          firebase
            .firestore()
            .collection("users")
            .doc(payload.uid)
        ),
        context.bindFirestoreRef(
          "teamAccount",
          firebase
            .firestore()
            .collection("teams")
            .where(`members.${payload.uid}`, "!=", false)
            .limit(1)
        ),
        context.bindFirestoreRef(
          "publicProfile",
          firebase
            .firestore()
            .collection("publicProfiles")
            .doc(payload.uid)
        )
      ]);
    }),
    unbindCurrentUser: firestoreAction(context => {
      return Promise.all([
        context.unbindFirestoreRef("currentUser"),
        context.unbindFirestoreRef("publicProfile"),
        context.unbindFirestoreRef("teamAccount")
      ]);
    }),
    updatePublicProfile({ commit }, payload) {
      commit("setLoading", true);
      return firebase
        .firestore()
        .collection("publicProfiles")
        .doc(payload.uid)
        .set(
          {
            ...payload.obj
          },
          { merge: true }
        )
        .then(() => {
          commit("setLoading", false);
          return true;
        })
        .catch(error => {
          commit("setError", error);
          commit("setLoading", false);
          throw error;
        });
    },
    verifyEmail(context, emailObj) {
      let verifyEmailFunction = firebase
        .functions()
        .httpsCallable("verifyEmail");
      return verifyEmailFunction(emailObj);
    },
    saveLogo({ commit }, payload) {
      return new Promise(resolve => {
        commit("setLoading", true);
        if (
          (payload.file.type !== "image/png" &&
            payload.file.type !== "image/jpg" &&
            payload.file.type !== "image/svg+xml" &&
            payload.file.type !== "image/svg" &&
            payload.file.type !== "image/jpeg") ||
          payload.file.size > 2 * 1000 * 1000
        ) {
          commit("setLoading", false);
          commit("setError", {
            message: "Only .PNG and .JPG file less than 2MB please."
          });
          resolve();
        }
        const ext = payload.file.name.split(".").pop();
        let ref = firebase
          .storage()
          .ref()
          .child(`${payload.uid}.${ext}`);
        ref
          .put(payload.file)
          .then(snapshot => {
            return snapshot.ref.parent
              .child(`${payload.uid}.${ext}`)
              .getDownloadURL();
          })
          .then(downloadURL => {
            return firebase
              .firestore()
              .collection("publicProfiles")
              .doc(payload.uid)
              .set(
                {
                  logoUrl: downloadURL,
                  filename: `${payload.uid}.${ext}`,
                  useThumbnail: true
                },
                { merge: true }
              );
          })
          .then(() => {
            commit("setLoading", false);
            resolve();
          })
          .catch(error => {
            commit("setError", error);
            commit("setLoading", false);
            resolve();
          });
      });
    }
  }
};
