import Vue from "vue";
import Vuex from "vuex";
import authData from "./modules/authData";
import profileData from "./modules/profileData";
import sharedData from "./modules/sharedData";
import gameData from "./modules/gameData";
import lookData from "./modules/lookData";
import templatesData from "./modules/templatesData";
import gamesListData from "./modules/gamesListData";
import teamsData from "./modules/teamsData";
import paymentData from "./modules/paymentData";
import leanGameData from "./modules/leanGameData";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    profileData,
    authData,
    sharedData,
    lookData,
    gameData,
    teamsData,
    paymentData,
    gamesListData,
    templatesData,
    leanGameData
  }
});
