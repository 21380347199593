function sortGames(a, b) {
  let gap =
    b.created_at && a.created_at
      ? b.created_at.seconds - a.created_at.seconds
      : 0;
  let ax = [];
  let bx = [];
  if (Math.abs(gap) > 5) {
    return gap;
  } else {
    a.name.replace(/(\d+)|(\D+)/g, function(_, $1, $2) {
      ax.push([$1 || Infinity, $2 || ""]);
    });
    b.name.replace(/(\d+)|(\D+)/g, function(_, $1, $2) {
      bx.push([$1 || Infinity, $2 || ""]);
    });
    while (ax.length && bx.length) {
      var an = ax.shift();
      var bn = bx.shift();
      var nn = an[0] - bn[0] || an[1].localeCompare(bn[1]);
      if (nn) return nn;
    }
    return ax.length - bx.length;
  }
}
export { sortGames };
