export default {
  title: "Transparent Beer Game",
  id: "transptemp",
  icon: "telescope",
  description:
    "Default MIT rules, but players have full visibility and can discuss in the chat",
  type: "beergame",
  config: {
    params: {
      totalWeeks: 20,
      transparent: true,
      timer: 60000,
      playTimer: false,
      product: "beer",
      timeUnit: "week",
      consumerDemand: [
        4,
        4,
        4,
        4,
        8,
        8,
        8,
        8,
        8,
        8,
        8,
        8,
        8,
        8,
        8,
        8,
        8,
        8,
        8,
        8
      ],
      configMessages: {
        allowChat: true,
        backlogMessage: {
          sentTo: [],
          active: true,
          message:
            "Watch out, you didn't have enough stock to fulfill the demand !",
          moreThan: 0
        },
        stockMessage: {
          sentTo: [],
          active: true,
          message:
            "Try to keep a reasonable amount of stock as it impacts your costs !",
          moreThan: 30
        },
        scheduleList: [
          {
            applyWeek: 0,
            message:
              "It's time to send your Order! Decide the quantity based on the previous demand, your stock and future planned deliveries.",
            to: ["all"]
          }
          // {
          //   applyWeek: 3,
          //   message:
          //     "The marketing team wants to start a promotion in turn 5, they expect final demand to double.",
          //   to: ["1", "200", "300", "400"]
          // }
        ]
      },
      currency: "$",
      debriefContent: [
        "Global Results",
        "Fill Rate",
        "Facility Charts",
        "Orders/Stock Charts",
        "Variability",
        "Lead times",
        "To Go Further"
      ]
    },
    facilities: {
      "1": {
        type: "shop",
        name: "retailer",
        icon: "cart",
        id: "1",
        supplier_id: "200",
        validatedOrder: false,
        stock: 12,
        backlog: 0,
        cost: 0,
        revenue: 0,
        allowBacklog: true,
        unitPrice: 0,
        moq: 0,
        batchSize: 0,
        stockCost: 0.5,
        backlogCost: 1,
        missedSaleCost: 0,
        visibleWeeks: 1,
        startOrders: 4,
        ordertime: 1,
        leadtime: 3
      },
      "200": {
        type: "warehouse",
        name: "wholesaler",
        icon: "domain",
        id: "200",
        supplier_id: "300",
        client_id: "1",
        validatedOrder: false,
        stock: 12,
        backlog: 0,
        cost: 0,
        revenue: 0,
        allowBacklog: true,
        unitPrice: 0,
        moq: 0,
        batchSize: 0,
        stockCost: 0.5,
        backlogCost: 1,
        missedSaleCost: 0,
        visibleWeeks: 1,
        startOrders: 4,
        ordertime: 1,
        leadtime: 3
      },
      "300": {
        type: "warehouse",
        name: "distributor",
        icon: "call-split",
        id: "300",
        supplier_id: "400",
        client_id: "200",
        validatedOrder: false,
        stock: 12,
        backlog: 0,
        cost: 0,
        revenue: 0,
        allowBacklog: true,
        unitPrice: 0,
        moq: 0,
        batchSize: 0,
        stockCost: 0.5,
        backlogCost: 1,
        missedSaleCost: 0,
        visibleWeeks: 1,
        startOrders: 4,
        ordertime: 1,
        leadtime: 3
      },
      "400": {
        type: "factory",
        name: "manufacturer",
        icon: "factory",
        id: "400",
        client_id: "300",
        validatedOrder: false,
        stock: 12,
        backlog: 0,
        cost: 0,
        revenue: 0,
        allowBacklog: true,
        unitPrice: 0,
        moq: 0,
        batchSize: 0,
        stockCost: 0.5,
        backlogCost: 1,
        missedSaleCost: 0,
        visibleWeeks: 1,
        startOrders: 4,
        ordertime: 1,
        leadtime: 3
      }
    }
  }
};
