/* global  analytics */
import Vue from "vue";
import Router from "vue-router";
import store from "./store";
import { ToastProgrammatic as Toast } from "buefy";

Vue.use(Router);

let router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      meta: {
        title: "The Beergame App",
        showFooter: true,
        metaTags: [
          {
            name: "description",
            content: "Beergame app homepage"
          },
          {
            property: "og:description",
            content: "Beergame app homepage"
          }
        ]
      },
      name: "home",
      component: () =>
        import(/* webpackChunkName: "login" */ "./views/home/Home.vue")
    },
    {
      path: "/signup",
      meta: { loginPage: true, hideNavbar: true },
      name: "signup",
      component: () =>
        import(/* webpackChunkName: "login" */ "./views/auth/Signup.vue")
    },
    {
      path: "/login",
      meta: { loginPage: true, hideNavbar: true },
      name: "login",
      component: () =>
        import(/* webpackChunkName: "login" */ "./views/auth/Login.vue")
    },
    {
      path: "/auth-action",
      meta: { hideNavbar: true },
      name: "auth-action",
      component: () =>
        import(
          /* webpackChunkName: "authaction" */ "./views/auth/AuthEmailAction.vue"
        )
    },
    {
      path: "/demo",
      name: "demo",
      component: () =>
        import(/* webpackChunkName: "authaction" */ "./views/play/GameDemo.vue")
    },
    {
      path: "/quote",
      name: "quote",
      meta: { hideNavbar: true },
      component: () =>
        import(/* webpackChunkName: "authaction" */ "./views/Quote.vue")
    },
    {
      path: "/dashboard",
      meta: { requiresEmail: true, showFooter: false },
      name: "dashboard-home",
      component: () =>
        import(
          /* webpackChunkName: "dashboard" */ "./views/dashboard/Dashboard.vue"
        )
    },
    {
      path: "/dashboard/:tab",
      meta: {
        requiresEmail: true,
        showFooter: false
      },
      name: "dashboard",
      component: () =>
        import(
          /* webpackChunkName: "dashboard" */ "./views/dashboard/Dashboard.vue"
        )
    },
    {
      path: "/user/:uid",
      name: "publicPage",
      meta: { userPage: true, custoLookPage: true },
      component: () =>
        import(
          /* webpackChunkName: "gameslist" */ "./views/play/PublicPage.vue"
        )
    },
    { path: "/game/:id", redirect: "/game/:id/intro" },
    {
      path: "/game/:id/intro",
      meta: { gamePage: true, custoLookPage: true },
      name: "game",
      component: () =>
        import(/* webpackChunkName: "game" */ "./views/play/GameIntro.vue")
    },
    {
      path: "/game/:id/play",
      meta: { gamePage: true, stagePage: true, custoLookPage: true },
      name: "play",
      component: () =>
        import(/* webpackChunkName: "stage" */ "./views/play/Play.vue")
    },
    {
      path: "/game/:id/results",
      meta: { gamePage: true, custoLookPage: true },
      name: "debrief",
      component: () =>
        import(/* webpackChunkName: "stage" */ "./views/play/GameDebrief.vue")
    }
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else if (to.hash) {
      return {
        selector: to.hash
      };
    } else {
      return { x: 0, y: 0 };
    }
  }
});

router.afterEach(() => {
  if (analytics) {
    analytics.page();
  }
});

router.beforeEach(async (to, from, next) => {
  let requiresEmail = to.matched.some(record => record.meta.requiresEmail);
  let isLoginPage = to.matched.some(record => record.meta.loginPage);
  let hideNavbar = to.matched.some(record => record.meta.hideNavbar);
  let isGamePage = to.matched.some(record => record.meta.gamePage);
  let isUserPage = to.matched.some(record => record.meta.userPage);
  let isCustoPage = to.matched.some(record => record.meta.custoLookPage);
  let successRoute = "next";
  let foundPortal = null;
  store.commit("clearError");

  if (window) {
    let hos = window.location.hostname;
    // hos = "colpal.zensimu.com";
    let subdo = hos.split(".")[0];
    if (
      subdo !== "beergameapp" &&
      subdo !== "app" &&
      subdo !== "zensimu" &&
      subdo !== "bgapp" &&
      subdo !== "zensim" &&
      subdo !== "app" &&
      subdo !== "localhost"
    ) {
      if (store.getters.portalLook) {
        foundPortal = true;
      } else {
        foundPortal = await store.dispatch("updatePortal", subdo);
      }
    }
  }

  if (to.query.joinTeam) {
    Toast.open({
      message:
        to.name === "dashboard"
          ? "Joining team.."
          : "Create an account to join the team",
      duration: 1500
    });
    store.commit("setJoinTeam", to.query.joinTeam);
  }
  if (to.query.startTrial) {
    Toast.open({
      message:
        to.name === "dashboard"
          ? "Starting trial.."
          : "Login or Signup to start trial",
      duration: 1500
    });
    store.commit("setStartTrialPack", to.query.startTrial);
  }

  if (hideNavbar) {
    store.commit("setShowNavbar", false);
  } else {
    store.commit("setShowNavbar", true);
  }
  let user = await store.dispatch("getLoginStatus");
  if (!user.email && requiresEmail) {
    Toast.open({
      message: "Please login to access this page",
      duration: 1500
    });
    successRoute = "login";
  } else if (user && isLoginPage) {
    if (user.email) {
      successRoute = "dashboard";
    } else {
      successRoute = "";
    }
  }

  if (isGamePage) {
    let gameId = to.params["id"];
    let rebind = false; // check if a new binding needs to be performed on current store game.
    if (!store.getters.currentGame) {
      rebind = true;
    } else if (store.getters.currentGame.id !== gameId) {
      rebind = true;
    } else {
      rebind = false;
    }
    if (rebind) {
      await store
        .dispatch("bindCurrentGame", { id: gameId })
        .then(async game => {
          if (!game || game.archived || game.deleted) {
            Toast.open({
              message:
                game && game.archived
                  ? "This game is archived"
                  : "Unknown game page",
              duration: 1500
            });
            successRoute = "back";
          } else if (
            !store.getters.currentWorkspace ||
            store.getters.currentWorkspace.id !== game.creator
          ) {
            if (game.creator) {
              await store.dispatch("bindCurrentWorkspace", {
                id: game.creator
              });
            }
          }
        });
    } else if (
      store.getters.currentGame.archived ||
      store.getters.currentGame.deleted
    ) {
      Toast.open({
        message: "This game is no longer active",
        duration: 1500
      });
      successRoute = "back";
    }
  }

  if (isUserPage) {
    let foundPublicProfile = null;
    // check if a new binding needs to be performed on current store workspace.
    if (
      !store.getters.currentWorkspace ||
      store.getters.currentWorkspace.id !== to.params["uid"]
    ) {
      foundPublicProfile = await store.dispatch("bindCurrentWorkspace", {
        id: to.params["uid"]
      });
    } else {
      foundPublicProfile = store.getters.currentWorkspace;
    }
    if (!foundPublicProfile) {
      successRoute = "/";
    } else if (
      !store.getters.extUserGamesList ||
      !store.getters.extUserGamesList[0] ||
      store.getters.extUserGamesList[0].creator !== to.params["uid"]
    ) {
      await store.dispatch("bindExtUserGamesList", { uid: to.params["uid"] });
    }
  }
  try {
    if (successRoute === "next") {
      if (!foundPortal) {
        store.commit("setShowCustoLook", isCustoPage);
      }
      return next();
    } else if (successRoute === "back" && from.path) {
      if (from.path) {
        return next(from.path);
      } else {
        return next("/");
      }
    } else if (successRoute) {
      return next(successRoute);
    } else {
      return next("/");
    }
  } catch (error) {
    return console.info(error);
  }
});

export default router;
