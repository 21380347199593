import firebase from "@/firebaseConfig";
import { firestoreAction } from "vuexfire";
import { DialogProgrammatic as Dialog } from "buefy";
import router from "@/router";
// import axios from "axios";

export default {
  state: {
    movingArrows: [],
    openFaciPanel: null,
    leanInterval: null,
    currentLeanGameTimeout: null,
    currentLeanGameMinute: 0,
    currentProducts: [],
    leanProductFigures: {
      dotsSheet: () =>
        import("@/components/lean/Play/Products/DotsSheetFigure.vue")
    },
    leanParams: {
      sizeToCol: {
        1: "is-full",
        2: "is-half",
        3: "is-4",
        4: "is-3",
        5: "is-one-fifth",
        6: "is-2"
      },
      allMetricsParams: {
        waitTime: { label: "Waiting in queue", color: "#fbacad", rank: 5 },
        moveTime: { label: "Transportation", color: "#FFDEA7", rank: 4 },
        workdeskTime: {
          label: "Processing time",
          color: "#06BD98",
          rank: 1
        },
        changeoverTime: {
          label: "Tool Changeover time",
          color: "#86B9DB",
          rank: 2
        },
        loadTime: { label: "Loading/Unloading", color: "darkgrey", rank: 3 }
      },
      availShapes: {
        circle: {
          id: "circle",
          shape: "circle",
          icon: "checkbox-blank-circle-outline"
        },
        square: { id: "square", shape: "square", icon: "square-outline" }
      },
      availColors: {
        green: {
          id: "green",
          colorName: "Green",
          colorHex: "#6ADCD1",
          class: "is-success"
        },
        red: {
          id: "red",
          colorName: "Red",
          colorHex: "#FF4736",
          class: "is-danger"
        },
        blue: {
          id: "blue",
          colorName: "Blue",
          colorHex: "#70C3FF",
          class: "is-info"
        },
        yellow: {
          id: "yellow",
          colorName: "Yellow",
          colorHex: "#ffdd57",
          class: "is-warning"
        }
        // pink: {
        //   id: "pink",
        //   colorName: "Pink",
        //   colorHex: "#FFBEC2",
        //   class: "is-primary is-light"
        // },
        // grey: {
        //   id: "grey",
        //   colorName: "Grey",
        //   colorHex: "#ddd",
        //   class: "is-light"
        // }
      }
    }
  },
  mutations: {
    setOpenFaciPanel(state, payload) {
      if (!payload.routeUpdated) {
        router.push({
          name: "play",
          params: { id: payload.gid },
          query: payload.fid ? { station: payload.fid } : null
        });
      }
      state.openFaciPanel = payload.fid;
    },
    setCurrentLeanGameMinute(state, payload) {
      state.currentLeanGameMinute = payload;
    },
    setCurrentLeanGameTimeout(state, payload) {
      state.currentLeanGameTimeout = payload;
    },
    startMovingArrows(state, payload) {
      state.movingArrows.push(payload);
    },
    stopMovingArrows(state, payload) {
      if (payload === "all") {
        state.movingArrows = [];
      } else {
        state.movingArrows = state.movingArrows.filter(ma => ma !== payload);
      }
    }
  },
  getters: {
    movingArrows: state => state.movingArrows,
    openFaciPanel: state => state.openFaciPanel,
    leanParams: state => state.leanParams,
    currentProducts: state => state.currentProducts,
    currentLeanGameOrders: (state, getters) => {
      return getters.currentGame && getters.currentGame.type === "leangame"
        ? getters.currentGame.params.consumerDemand.filter(dem => {
            return (
              dem.created_min < getters.currentLeanGameMinute ||
              getters.currentGame.params.consumerDemandParams.visibility
            );
          })
        : [];
    },
    isLeanGameActive: (state, getters) => {
      return getters.currentGame && !getters.currentGame.params.game_end_at;
    },
    currentLeanGameMinute: state => state.currentLeanGameMinute,
    currentLeanGameTimeout: state => state.currentLeanGameTimeout,
    leanProductFigures: state => state.leanProductFigures,
    movementDurations: (state, getters) => {
      if (
        !getters.currentGame ||
        !getters.currentGame.facilities ||
        getters.currentGame.type !== "leangame"
      ) {
        return null;
      }
      let retObj = {};
      Object.values(getters.currentGame.facilities).forEach(faci => {
        retObj[faci.id] = { to: {} };
        Object.values(getters.currentGame.facilities).forEach(otherfac => {
          let dist =
            Math.abs(faci.position[0] - otherfac.position[0]) +
            Math.abs(faci.position[1] - otherfac.position[1]);
          retObj[faci.id].to[otherfac.id] = Math.abs(
            Math.max(0, dist - 1) * getters.currentGame.params.moveCellTime
          );
        });
      });
      return retObj;
    }
  },
  actions: {
    updateLeanGameMinutes({ getters, commit, dispatch }) {
      if (!getters.currentGame || !getters.currentGame.type === "leangame") {
        return;
      }
      const endSec = getters.currentGame.params.game_end_at
        ? getters.currentGame.params.game_end_at.seconds
        : Date.now() / 1000;
      const startSec = getters.currentGame.params.game_start_at
        ? getters.currentGame.params.game_start_at.seconds
        : Date.now() / 1000;
      commit(
        "setCurrentLeanGameMinute",
        Math.ceil((10 * (endSec - startSec)) / 60) / 10
      );
      if (getters.currentGame.params.game_end_at) {
        if (getters.currentLeanGameTimeout) {
          clearTimeout(getters.currentLeanGameTimeout);
        }
        return;
      }
      commit(
        "setCurrentLeanGameTimeout",
        setTimeout(() => {
          dispatch("updateLeanGameMinutes");
        }, 1000)
      );
    },
    createLeanProduct({ commit }, payload) {
      commit("clearError");
      return firebase
        .firestore()
        .collection("games")
        .doc(payload.gid)
        .collection("products")
        .add({
          ...payload
        })
        .then(prod => {
          return { id: prod.id, ...payload };
        })
        .catch(error => {
          commit("setError", error);
        });
    },
    launchLeanAutoPlay({ getters }) {
      let leanAutoPlay = firebase.functions().httpsCallable("leanAutoPlay");
      return leanAutoPlay({ gid: getters.currentGame.id });
    },
    createNextRun({ commit }, payload) {
      commit("setLoading", true);
      let createRunFunc = firebase
        .functions()
        .httpsCallable("createNewLeanRun");
      return createRunFunc(payload)
        .then(() => {
          commit("setLoading", false);
          return payload;
        })
        .catch(error => {
          commit("setLoading", false);
          commit("setError", error);
        });
    },
    updateProductsDocs: firestoreAction(({ commit }, payload) => {
      commit("clearError");
      let updatePromises = [];
      payload.forEach(prodObj => {
        updatePromises.push(
          firebase
            .firestore()
            .collection("games")
            .doc(prodObj.gid)
            .collection("products")
            .doc(prodObj.pid)
            .update({ ...prodObj.obj })
        );
      });
      return Promise.all(updatePromises)
        .then(() => {
          return payload;
        })
        .catch(error => {
          commit("setError", error);
        });
    }),
    getProductsOnce({ commit }, payload) {
      return firebase
        .firestore()
        .collection("games")
        .doc(payload.id)
        .collection("products")
        .get()
        .then(productDocs => {
          return {
            game: payload,
            products: productDocs.docs.map(doc => doc.data())
          };
        })
        .catch(error => {
          commit("setError", error);
        });
    },
    updateLeanStartingStocks: firestoreAction(async ({ commit }, payload) => {
      commit("setLoading", true);
      let resetStocksFunc = firebase
        .functions()
        .httpsCallable("resetLeanStartingStocks");
      return resetStocksFunc(payload).then(() => {
        commit("setLoading", false);
        return true;
      });
    }),
    bindProducts: firestoreAction((context, payload) => {
      if (
        !payload.force &&
        context.state.currentProducts &&
        context.state.currentProducts.length
      ) {
        return true;
      }
      context.commit("setLoading", true);
      return context
        .bindFirestoreRef(
          "currentProducts",
          firebase
            .firestore()
            .collection("games")
            .doc(payload.id)
            .collection("products")
        )
        .then(ret => {
          context.commit("setLoading", false);
          return ret;
        });
    }),
    startLeanRun(context, payload) {
      let scheduleFunc = firebase
        .functions()
        .httpsCallable("leanScheduleAllTasks");
      let timestamp = firebase.firestore.FieldValue.serverTimestamp();
      let updatePromises = [];
      payload.forEach(gameObj => {
        updatePromises.push(
          context
            .dispatch("updateLeanStartingStocks", {
              gid: gameObj.id
            })
            .then(() => {
              return context.dispatch("updateGameDoc", [
                {
                  obj: {
                    [`params.game_start_at`]: timestamp,
                    [`params.playTimer`]: true
                  },
                  type: gameObj.type,
                  gid: gameObj.id
                }
              ]);
            })
            .then(() => {
              return scheduleFunc({
                gid: gameObj.id,
                type: gameObj.type,
                totalDurationMin:
                  gameObj.params.consumerDemandParams.totalDuration,
                user_id: firebase.auth().currentUser.uid
              });
            })
        );
      });
      return Promise.all(updatePromises);
    },
    resetLeanRun(context, payload) {
      return new Promise(resolve => {
        let updatePromises = [];
        Dialog.confirm({
          message: `Be careful! This will erase all the products, reset the customer orders and the game timer to 0.`,
          size: "is-medium",
          confirmText: "Reset Run",
          type: "is-success",
          onConfirm: () => {
            context.commit("setLoading", true);
            payload.forEach(gameObj => {
              updatePromises.push(
                new Promise((upResolve, upReject) => {
                  context
                    .dispatch("updateLeanStartingStocks", {
                      gid: gameObj.id
                    })
                    .then(() => {
                      let newDemTab = [...gameObj.params.consumerDemand];
                      newDemTab.forEach(dem => (dem.fulfilled_at = null));
                      return context.dispatch("updateGameDoc", [
                        {
                          obj: {
                            finished: false,
                            [`params.game_start_at`]: null,
                            [`params.game_end_at`]: null,
                            [`params.consumerDemand`]: newDemTab,
                            [`params.nextRank`]: {},
                            [`params.playTimer`]: false
                          },
                          gid: gameObj.id
                        }
                      ]);
                    })
                    .then(() => {
                      upResolve();
                    })
                    .catch(() => {
                      upReject();
                    });
                })
              );
            });
            Promise.all(updatePromises)
              .then(() => {
                context.commit("setLoading", false);
                resolve();
              })
              .catch(error => {
                context.commit("setError", error);
                context.commit("setLoading", false);
                resolve();
              });
          },
          onCancel() {
            resolve();
          }
        });
      });
    }
  }
};
