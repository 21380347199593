import firebase from "@/firebaseConfig";
import { firestoreAction } from "vuexfire";
import { sortGames } from "@/store/helperFunctions.js";

export default {
  state: {
    teamGamesList: [],
    subTeamGamesList0: [],
    subTeamGamesList1: [],
    subTeamGamesList2: [],
    subTeamGamesList3: [],
    subTeamGamesList4: [],
    subTeamGamesList5: [],
    nbSubTeams: 0,
    teamTemplatesList: [],
    subTeamTeamplatesList0: [],
    subTeamTeamplatesList1: [],
    subTeamTeamplatesList2: [],
    subTeamTeamplatesList3: [],
    subTeamTeamplatesList4: [],
    subTeamTeamplatesList5: [],
    nbSubTeamsTemp: 0,
    joinTeam: null
  },
  mutations: {
    setJoinTeam(state, payload) {
      state.joinTeam = payload;
    },
    setNbSubTeams: (state, payload) => {
      state.nbSubTeams = payload;
    },
    setNbSubTeamsTemp: (state, payload) => {
      state.nbSubTeamsTemp = payload;
    }
  },
  getters: {
    joinTeam: state => {
      return state.joinTeam;
    },
    teamGamesList: state => {
      let arr = [
        ...state.teamGamesList,
        ...state.subTeamGamesList0,
        ...state.subTeamGamesList1,
        ...state.subTeamGamesList2,
        ...state.subTeamGamesList3,
        ...state.subTeamGamesList4,
        ...state.subTeamGamesList5
      ];
      return arr.filter(game => !game.deleted);
    },
    teamTemplates: state => {
      let arr = [
        ...state.teamTemplatesList,
        ...state.subTeamTeamplatesList0,
        ...state.subTeamTeamplatesList1,
        ...state.subTeamTeamplatesList2,
        ...state.subTeamTeamplatesList3,
        ...state.subTeamTeamplatesList4,
        ...state.subTeamTeamplatesList5
      ];
      return arr;
    },
    concatGamesList: (s, getters) => {
      let tab = [];
      getters.userGamesList.forEach(gg =>
        tab.push({ ...gg, id: gg.id, myGame: true, creatorName: "Me" })
      );
      getters.teamGamesList.forEach(gg =>
        tab.push({
          ...gg,
          id: gg.id,
          myGame: false,
          creatorName: getters.teamAccount.members[gg.creator].name
        })
      );
      return tab.sort(sortGames);
    }
  },
  actions: {
    addTeamMember({ getters, commit }, payload) {
      commit("setLoading", true);
      let addFunc = firebase.functions().httpsCallable("addTeamMember");
      addFunc({
        adminName: getters.currentUser.name
          ? getters.currentUser.name
          : `${getters.currentUser.firstname} ${getters.currentUser.lastname}`,
        ...payload
      })
        .then(() => {
          commit("setLoading", false);
        })
        .catch(error => {
          commit("setLoading", false);
          commit("setError", error);
        });
    },
    removeTeamMember({ commit }, payload) {
      commit("setLoading", true);
      let removeFunc = firebase.functions().httpsCallable("removeTeamMember");
      removeFunc(payload)
        .then(() => {
          commit("setLoading", false);
        })
        .catch(error => {
          commit("setLoading", false);
          commit("setError", error);
        });
    },
    async joinNewTeam({ commit, getters }) {
      commit("setLoading", true);
      let joinFunc = firebase.functions().httpsCallable("joinTeamMember");
      if (getters.joinTeam) {
        return joinFunc({
          teamId: getters.joinTeam,
          userId: getters.authUser.uid,
          userEmail: getters.authUser.email,
          name: getters.currentUser.name
            ? getters.currentUser.name
            : `${getters.currentUser.firstname} ${getters.currentUser.lastname}`
        })
          .then(ret => {
            commit("setLoading", false);
            return ret;
          })
          .catch(error => {
            commit("setLoading", false);
            setTimeout(() => commit("setError", error), 500);
            return false;
          });
      }
      return true;
    },
    updateTeam({ commit }, payload) {
      commit("setLoading", true);
      commit("clearError");
      return firebase
        .firestore()
        .collection("teams")
        .doc(payload.id)
        .update(payload.obj)
        .then(() => {
          commit("setLoading", false);
          return payload;
        })
        .catch(error => {
          commit("setLoading", false);
          commit("setError", error);
          return false;
        });
    },
    bindTeamGamesList: firestoreAction((context, payload) => {
      context.commit("setLoading", true);
      return context
        .bindFirestoreRef(
          "teamGamesList",
          firebase
            .firestore()
            .collection("games")
            .where("creator", "in", payload)
            .where("deleted", "==", false),
          { maxRefDepth: 3 }
        )
        .then(() => {
          context.commit("setLoading", false);
        });
    }),
    bindSubteamGamesList: firestoreAction((context, payload) => {
      context.commit("setLoading", true);
      context.commit("setNbSubTeams", context.state.nbSubTeams + 1);
      return context
        .bindFirestoreRef(
          `subTeamGamesList${context.state.nbSubTeams - 1}`,
          firebase
            .firestore()
            .collection("games")
            .where("creator", "in", payload)
            .where("deleted", "==", false),
          { maxRefDepth: 3 }
        )
        .then(() => {
          context.commit("setLoading", false);
        });
    }),
    bindTeamTemplatesList: firestoreAction((context, payload) => {
      context.commit("setLoading", true);
      return context
        .bindFirestoreRef(
          "teamTemplatesList",
          firebase
            .firestore()
            .collection("templates")
            .where("creator", "in", payload),
          { maxRefDepth: 3 }
        )
        .then(() => {
          context.commit("setLoading", false);
        });
    }),
    bindSubteamTeamplatesList: firestoreAction((context, payload) => {
      context.commit("setLoading", true);
      context.commit("setNbSubTeamsTemp", context.state.nbSubTeamsTemp + 1);
      return context
        .bindFirestoreRef(
          `subTeamTeamplatesList${context.state.nbSubTeamsTemp - 1}`,
          firebase
            .firestore()
            .collection("templates")
            .where("creator", "in", payload),
          { maxRefDepth: 3 }
        )
        .then(() => {
          context.commit("setLoading", false);
        });
    })
  }
};
