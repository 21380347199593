export default {
  title: "Short Lean Game - Round 2",
  icon: "radiobox-marked",
  id: "shortleanround2",
  description: "Simple dots sheets - for 4 players - incl. all improvements",
  type: "leangame",
  config: {
    params: {
      game_start_at: null,
      game_end_at: null,
      playTimer: false,
      timer: 3000,
      allowComputer: true,
      nextRank: {},
      configMessages: { allowChat: true },
      moveCellTime: 2000,
      improvements: {
        batch: true,
        movestations: true,
        autoinspect: true,
        balancestations: true,
        heijunka: true,
        kanban: true,
        smed: true,
        pokayoke: true
      },
      consumerDemandParams: {
        totalDuration: 6,
        visibility: true,
        firstonly: true,
        orderFreqSec: 6.2,
        objLTSec: 60,
        levelMix: true,
        levelQty: true,
        levelQtyPcs: 1
      },
      consumerDemand: [
        {
          created_min: 0,
          template_id: "A",
          obj_lead_time: 1,
          fulfilled_at: null,
          qty: 1
        },
        {
          created_min: 0.103,
          template_id: "B",
          obj_lead_time: 1,
          fulfilled_at: null,
          qty: 1
        },
        {
          created_min: 0.206,
          template_id: "A",
          obj_lead_time: 1,
          fulfilled_at: null,
          qty: 1
        },
        {
          created_min: 0.309,
          template_id: "B",
          obj_lead_time: 1,
          fulfilled_at: null,
          qty: 1
        },
        {
          created_min: 0.412,
          template_id: "A",
          obj_lead_time: 1,
          fulfilled_at: null,
          qty: 1
        },
        {
          created_min: 0.515,
          template_id: "B",
          obj_lead_time: 1,
          fulfilled_at: null,
          qty: 1
        },
        {
          created_min: 0.618,
          template_id: "A",
          obj_lead_time: 1,
          fulfilled_at: null,
          qty: 1
        },
        {
          created_min: 0.721,
          template_id: "B",
          obj_lead_time: 1,
          fulfilled_at: null,
          qty: 1
        },
        {
          created_min: 0.824,
          template_id: "A",
          obj_lead_time: 1,
          fulfilled_at: null,
          qty: 1
        },
        {
          created_min: 0.927,
          template_id: "B",
          obj_lead_time: 1,
          fulfilled_at: null,
          qty: 1
        },
        {
          created_min: 1.03,
          template_id: "A",
          obj_lead_time: 1,
          fulfilled_at: null,
          qty: 1
        },
        {
          created_min: 1.133,
          template_id: "B",
          obj_lead_time: 1,
          fulfilled_at: null,
          qty: 1
        },
        {
          created_min: 1.236,
          template_id: "A",
          obj_lead_time: 1,
          fulfilled_at: null,
          qty: 1
        },
        {
          created_min: 1.339,
          template_id: "B",
          obj_lead_time: 1,
          fulfilled_at: null,
          qty: 1
        },
        {
          created_min: 1.442,
          template_id: "A",
          obj_lead_time: 1,
          fulfilled_at: null,
          qty: 1
        },
        {
          created_min: 1.545,
          template_id: "B",
          obj_lead_time: 1,
          fulfilled_at: null,
          qty: 1
        },
        {
          created_min: 1.648,
          template_id: "A",
          obj_lead_time: 1,
          fulfilled_at: null,
          qty: 1
        },
        {
          created_min: 1.751,
          template_id: "B",
          obj_lead_time: 1,
          fulfilled_at: null,
          qty: 1
        },
        {
          created_min: 1.854,
          template_id: "A",
          obj_lead_time: 1,
          fulfilled_at: null,
          qty: 1
        },
        {
          created_min: 1.957,
          template_id: "B",
          obj_lead_time: 1,
          fulfilled_at: null,
          qty: 1
        },
        {
          created_min: 2.06,
          template_id: "A",
          obj_lead_time: 1,
          fulfilled_at: null,
          qty: 1
        },
        {
          created_min: 2.163,
          template_id: "B",
          obj_lead_time: 1,
          fulfilled_at: null,
          qty: 1
        },
        {
          created_min: 2.266,
          template_id: "A",
          obj_lead_time: 1,
          fulfilled_at: null,
          qty: 1
        },
        {
          created_min: 2.369,
          template_id: "B",
          obj_lead_time: 1,
          fulfilled_at: null,
          qty: 1
        },
        {
          created_min: 2.472,
          template_id: "A",
          obj_lead_time: 1,
          fulfilled_at: null,
          qty: 1
        },
        {
          created_min: 2.575,
          template_id: "B",
          obj_lead_time: 1,
          fulfilled_at: null,
          qty: 1
        },
        {
          created_min: 2.678,
          template_id: "A",
          obj_lead_time: 1,
          fulfilled_at: null,
          qty: 1
        },
        {
          created_min: 2.781,
          template_id: "B",
          obj_lead_time: 1,
          fulfilled_at: null,
          qty: 1
        },
        {
          created_min: 2.884,
          template_id: "A",
          obj_lead_time: 1,
          fulfilled_at: null,
          qty: 1
        },
        {
          created_min: 2.987,
          template_id: "B",
          obj_lead_time: 1,
          fulfilled_at: null,
          qty: 1
        },
        {
          created_min: 3.09,
          template_id: "A",
          obj_lead_time: 1,
          fulfilled_at: null,
          qty: 1
        },
        {
          created_min: 3.193,
          template_id: "B",
          obj_lead_time: 1,
          fulfilled_at: null,
          qty: 1
        },
        {
          created_min: 3.296,
          template_id: "A",
          obj_lead_time: 1,
          fulfilled_at: null,
          qty: 1
        },
        {
          created_min: 3.399,
          template_id: "B",
          obj_lead_time: 1,
          fulfilled_at: null,
          qty: 1
        },
        {
          created_min: 3.502,
          template_id: "A",
          obj_lead_time: 1,
          fulfilled_at: null,
          qty: 1
        },
        {
          created_min: 3.605,
          template_id: "B",
          obj_lead_time: 1,
          fulfilled_at: null,
          qty: 1
        },
        {
          created_min: 3.708,
          template_id: "A",
          obj_lead_time: 1,
          fulfilled_at: null,
          qty: 1
        },
        {
          created_min: 3.811,
          template_id: "B",
          obj_lead_time: 1,
          fulfilled_at: null,
          qty: 1
        },
        {
          created_min: 3.914,
          template_id: "A",
          obj_lead_time: 1,
          fulfilled_at: null,
          qty: 1
        },
        {
          created_min: 4.017,
          template_id: "B",
          obj_lead_time: 1,
          fulfilled_at: null,
          qty: 1
        },
        {
          created_min: 4.12,
          template_id: "A",
          obj_lead_time: 1,
          fulfilled_at: null,
          qty: 1
        },
        {
          created_min: 4.223,
          template_id: "B",
          obj_lead_time: 1,
          fulfilled_at: null,
          qty: 1
        },
        {
          created_min: 4.326,
          template_id: "A",
          obj_lead_time: 1,
          fulfilled_at: null,
          qty: 1
        },
        {
          created_min: 4.429,
          template_id: "B",
          obj_lead_time: 1,
          fulfilled_at: null,
          qty: 1
        },
        {
          created_min: 4.532,
          template_id: "A",
          obj_lead_time: 1,
          fulfilled_at: null,
          qty: 1
        },
        {
          created_min: 4.635,
          template_id: "B",
          obj_lead_time: 1,
          fulfilled_at: null,
          qty: 1
        },
        {
          created_min: 4.738,
          template_id: "A",
          obj_lead_time: 1,
          fulfilled_at: null,
          qty: 1
        },
        {
          created_min: 4.841,
          template_id: "B",
          obj_lead_time: 1,
          fulfilled_at: null,
          qty: 1
        }
      ],
      currency: "$",
      mapSize: [3, 3],
      costTypes: {
        station: -50,
        opes: {
          // Successful:-1,
          // Failed:-1,
          Rework: -20
        },
        rawmaterial: -10,
        latepenalty: -40,
        qualitypenalty: -50,
        wip: -50,
        // fg: -50,
        sales: 100
      },
      productsTemplates: {
        A: {
          name: "Product A",
          batchSize: 1,
          template_id: "A",
          type: "dotsSheet",
          hideStatus: false,
          showGuides: true,
          design: {
            columns: [
              { label: "Green", dots: [] },
              { label: "Red", dots: [] }
              // { label: "Blue", dots: [] }
            ],
            targetRadius: 8,
            addTraces: []
          },
          operations: [
            {
              ope: "painting",
              id: "s1",
              status: null,
              params: {
                shape: "circle",
                color: "green",
                position: [0, 0]
              }
            },
            {
              ope: "painting",
              id: "s2",
              status: null,
              params: {
                shape: "circle",
                color: "red",
                position: [1, 0]
              }
            },
            {
              ope: "painting",
              id: "s3",
              status: null,
              params: {
                shape: "square",
                color: "red",
                position: [1, 1]
              }
            },
            {
              ope: "painting",
              id: "s4",
              status: null,
              params: {
                shape: "circle",
                color: "red",
                position: [1, 2]
              }
            },
            {
              ope: "painting",
              id: "s5",
              status: null,
              params: {
                shape: "square",
                color: "red",
                position: [1, 3]
              }
            }
            // {
            //   ope: "painting",
            //   id: "s7",
            //   status: null,
            //   params: {
            //     shape: "circle",
            //     color: "blue",
            //     position: [2, 0]
            //   }
            // }
          ]
        },
        B: {
          name: "Product B",
          type: "dotsSheet",
          batchSize: 1,
          template_id: "B",
          hideStatus: false,
          showGuides: true,
          design: {
            columns: [
              { label: "Green", dots: [] },
              { label: "Red", dots: [] }
              // { label: "Blue", dots: [] }
            ],
            targetRadius: 8,
            addTraces: []
          },
          operations: [
            {
              ope: "painting",
              id: "s1",
              status: null,
              params: {
                shape: "circle",
                color: "green",
                position: [0, 0]
              }
            },
            {
              ope: "painting",
              id: "s2",
              status: null,
              params: {
                shape: "circle",
                color: "green",
                position: [0, 1]
              }
            },
            {
              ope: "painting",
              id: "s3",
              status: null,
              params: {
                shape: "square",
                color: "red",
                position: [1, 0]
              }
            },
            {
              ope: "painting",
              id: "s4",
              status: null,
              params: {
                shape: "square",
                color: "red",
                position: [1, 1]
              }
            },
            {
              ope: "painting",
              id: "s5",
              status: null,
              params: {
                shape: "circle",
                color: "red",
                position: [1, 2]
              }
            }
            // {
            //   ope: "painting",
            //   id: "s6",
            //   status: null,
            //   params: {
            //     shape: "circle",
            //     color: "blue",
            //     position: [2, 0]
            //   }
            // },
            // {
            //   ope: "painting",
            //   id: "s7",
            //   status: null,
            //   params: {
            //     shape: "square",
            //     color: "blue",
            //     position: [2, 1]
            //   }
            // }
          ]
        }
      }
    },
    facilities: {
      "100": {
        id: "100",
        prioNextStation: "200",
        type: "inventoryShipping",
        name: "Warehouse",
        icon: "swap-horizontal-bold",
        maxCapacity: null,
        mandatoryStation: true,
        position: [0, 0],
        params: { startingStock: { A: 1, B: 0 }, kanbanCapa: null }
      },
      "200": {
        id: "200",
        prioNextStation: "300",
        type: "painting",
        name: "Painting Green",
        icon: "radiobox-marked",
        params: {
          startingStock: { A: 0, B: 1 },
          kanbanCapa: 1,
          changeoverDuration: 500,
          loadingDuration: 300,
          stampRemove: true,
          availShapes: ["circle"],
          availColors: ["green"]
        },
        position: [1, 0]
      },
      "300": {
        id: "300",
        prioNextStation: "400",
        type: "painting",
        name: "Painting Red Circles",
        icon: "radiobox-marked",
        params: {
          startingStock: { A: 1, B: 0 },
          kanbanCapa: 1,
          changeoverDuration: 500,
          loadingDuration: 300,
          stampRemove: true,
          availShapes: ["circle"],
          availColors: ["red"]
        },
        position: [1, 1]
      },
      // "400": {
      //   id: "400",
      //   prioNextStation: "500",
      //   type: "painting",
      //   name: "Painting Blue",
      //   icon: "radiobox-marked",
      //   params: {
      //     kanbanCapa: null,
      //     loadingDuration: 300,
      //     changeoverDuration: 2000,
      //     stampRemove: false,
      //     availShapes: ["circle", "square"],
      //     availColors: ["blue"]
      //   },
      //   position: [3, 1]
      // },
      "400": {
        id: "400",
        prioNextStation: "100",
        type: "painting",
        name: "Painting Red Squares",
        icon: "radiobox-marked",
        params: {
          startingStock: { A: 0, B: 1 },
          kanbanCapa: 1,
          loadingDuration: 300,
          stampRemove: true,
          changeoverDuration: 500,
          availShapes: ["square"],
          availColors: ["red"]
        },
        position: [0, 1]
      }
    }
  }
};
