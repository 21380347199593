import firebase from "@/firebaseConfig";
import axios from "axios";

export default {
  state: { quoteData: null },
  mutations: {
    setQuoteData(state, payload) {
      state.quoteData = payload;
    }
  },
  getters: { quoteData: state => state.quoteData },
  actions: {
    updateQuoteData({ commit }) {
      let apikey = "pk_01897982acd6f8866c6359b60b1b4bab";
      axios
        .post(
          "https://api.vatstack.com/v1/quotes",
          { amount: 100 },
          {
            headers: {
              Authorization: `Credential ${apikey}`
            }
          }
        )
        .then(result => {
          if (result.data.member_state === true) {
            commit("setQuoteData", {
              currency: "eur",
              symbol: "€",
              rate: result.data.vat.rate
            });
          } else {
            commit("setQuoteData", {
              currency: "usd",
              symbol: "$",
              rate: 0
            });
          }
        });
    },
    startCheckoutSession({ commit }, payload) {
      commit("setLoading", true);
      commit("clearError");
      if (!payload.name) {
        commit("setLoading", false);
        commit("setError", { message: "Error in the plan selection" });
        return false;
      } else {
        let createSessionFunc = firebase
          .functions()
          .httpsCallable("startCheckoutSession");
        createSessionFunc(payload)
          .then(sessionObj => {
            if (window.Stripe) {
              let stripe = window.Stripe(
                "pk_live_51GxEh7KgTeb7xZAcebfF9EOUK0mUV4ypOETdNwXE5BV230yi8i3y88lmit5cVSNyBas3tAEs9WTvhatFHKonPCLe00MwLQ3zov"
              );
              // let stripe = window.Stripe(
              //   "pk_test_51GxEh7KgTeb7xZAcJEJ9WqzEAgzJGoPPR84haFY0pKtEzSxZPcQYU67I0pZFy8p53gbMCDTUrnDfkK5OcnnJYJxn00Y1Qy43qf"
              // );
              setTimeout(() => {
                return stripe.redirectToCheckout({
                  sessionId: sessionObj.data
                });
              }, 5);
            }
          })
          .catch(error => {
            commit("setError", error);
            commit("setLoading", false);
            return error;
          });
      }
    }
  }
};
