import firebase from "@/firebaseConfig";
import router from "@/router";
import {
  ToastProgrammatic as Toast,
  DialogProgrammatic as Dialog
} from "buefy";
// import { firestoreAction } from "vuexfire";

export default {
  state: {
    authUser: null
  },
  mutations: {
    setAuthUser(state, payload) {
      state.authUser = payload;
    }
  },
  getters: {
    authUser: state => {
      return state.authUser;
    }
  },
  actions: {
    createAccount({ commit, dispatch, getters }, payload) {
      if (firebase.auth().currentUser) {
        commit("setError", {
          message:
            "Please log-out from your current account before creating a new one."
        });
        return false;
      } else {
        commit("setLoading", true);
        commit("clearError");
        return firebase
          .auth()
          .createUserWithEmailAndPassword(payload.email, payload.password)
          .then(userCredentials => {
            const timestamp = firebase.firestore.FieldValue.serverTimestamp();
            let filterObj = { ...payload };
            delete filterObj.password;
            let prom = dispatch("updateUserProfile", {
              ...filterObj,
              uid: userCredentials.user.uid,
              created_at: timestamp,
              ...(getters.portalLook && {
                portal: getters.portalLook.slug
              })
            });
            return prom;
          })
          .then(userDataObj => {
            Toast.open({
              message: `Welcome ${
                userDataObj.firstname ? userDataObj.firstname : userDataObj.name
              } !`,
              duration: 1500
            });
            router.push("/dashboard");
            return true;
          })
          .catch(error => {
            commit("setLoading", false);
            commit("setError", error);
            throw error;
          });
      }
    },
    login({ commit }, payload) {
      commit("setLoading", true);
      commit("clearError");
      firebase
        .auth()
        .signInWithEmailAndPassword(payload.email, payload.password)
        .then(userCredentials => {
          commit("setLoading", false);
          Toast.open({
            message: `Welcome back ${userCredentials.user.displayName} !`,
            duration: 1500
          });
          payload.router.push("/dashboard");
          // dispatch("submitForm", {
          //   formType: "login",
          //   fields: [
          //     {
          //       name: "email",
          //       value: payload.email
          //     },
          //     {
          //       name: "userid",
          //       value: userCredentials.user.uid
          //     }
          //   ]
          // });
        })
        .catch(error => {
          commit("setLoading", false);
          commit("setError", error);
        });
    },
    loginGoogle({ commit, dispatch }, payload) {
      commit("setLoading", true);
      commit("clearError");
      let provider = new firebase.auth.GoogleAuthProvider();
      provider.addScope("profile");
      provider.addScope("email");
      firebase
        .auth()
        .signInWithPopup(provider)
        .then(userCredentials => {
          // let myFormType = "login";
          // let myFields = [
          //   {
          //     name: "email",
          //     value: userCredentials.user.email
          //   },
          //   {
          //     name: "userid",
          //     value: userCredentials.user.uid
          //   }
          // ];
          // if (payload.obj.profileType) {
          //   myFormType = "signup";
          //   myFields.push({
          //     name: "firstname",
          //     value: userCredentials.user.displayName
          //   });
          //   myFields.push({
          //     name: "profile_type",
          //     value: payload.obj.profileType
          //   });
          // }
          // dispatch("submitForm", {
          //   formType: myFormType,
          //   fields: myFields
          // });
          let prom = dispatch("updateUserProfile", {
            uid: userCredentials.user.uid,
            name: userCredentials.user.displayName,
            email: userCredentials.user.email,
            ...payload.obj
          });
          return prom;
        })
        .then(userDataObj => {
          Toast.open({
            message: `Welcome ${userDataObj.name} !`,
            duration: 1500
          });
          payload.router.push("/dashboard");
        })
        .catch(error => {
          commit("setLoading", false);
          commit("setError", error);
        });
    },
    loginAnonymously({ commit }) {
      return new Promise((resolve, reject) => {
        commit("setLoading", true);
        firebase
          .auth()
          .signInAnonymously()
          .then(userCredentials => {
            resolve(userCredentials);
          })
          .catch(error => {
            commit("setLoading", false);
            reject(error);
            commit("setError", error);
          });
      });
    },
    async logout({ commit, dispatch, getters }) {
      commit("setLoading", true);
      commit("clearError");
      try {
        if (!firebase.auth().currentUser) {
          return true;
        }
        if (getters.currentUser && getters.currentUser.playingGame) {
          await dispatch("quitGame", getters.currentUser.playingGame);
        }
        commit("removeUser");
        commit("setLoading", false);
        await firebase.auth().signOut();
        router.push("/");
        return Promise.resolve();
      } catch (error) {
        commit("setError", error);
        commit("setLoading", false);
      }
    },
    async getLoginStatus({ getters, commit, dispatch }) {
      let au = getters.authUser;
      let cu = getters.currentUser;
      if (cu && au && cu.uid === au.uid) {
        return au;
      }
      return new Promise(resolve => {
        firebase.auth().onAuthStateChanged(async user => {
          if (user) {
            if (!getters.currentUser || getters.currentUser.uid !== user.uid) {
              await dispatch("bindCurrentUser", { uid: user.uid });
            }
            commit("setAuthUser", user);
            // Are actions stored to be applied after user logged in ?
            if (
              getters.authUser &&
              (getters.joinTeam || getters.startTrialPack)
            ) {
              if (getters.authUser.email) {
                if (getters.joinTeam) {
                  await dispatch("joinNewTeam");
                }
                if (getters.startTrialPack) {
                  await dispatch("startTrial", getters.startTrialPack);
                }
              } else {
                Dialog.confirm({
                  message:
                    "You first need to quit your current game and then signup/login as an instructor.",
                  confirmText: "Quit game",
                  type: "is-primary",
                  onConfirm: () => {
                    commit("setLoading", true);
                    dispatch("logout").then(() => {
                      router.push("/signup");
                      commit("setLoading", false);
                    });
                  }
                });
              }
            }
            dispatch("identifyUser", user);
            resolve(user);
          } else {
            await dispatch("unbindCurrentUser");
            commit("removeUser");
            commit("setAuthUser", null);
            resolve(false);
          }
        });
      });
    },
    sendResetPasswordEmail({ commit }, payload) {
      commit("setLoading", true);
      commit("clearError");
      let resFunc = firebase
        .functions()
        .httpsCallable("sendPasswordResetEmail");
      return resFunc(payload.email)
        .then(() => {
          commit("setLoading", false);
          return true;
        })
        .catch(() => {
          commit("setLoading", false);
          commit("setError", {
            message: "This email doesn't match any account."
          });
          return false;
        });
    },
    updateEmailValidation({ commit }, payload) {
      commit("setLoading", true);
      commit("clearError");
      return new Promise(resolve => {
        let actionInfo = null;
        firebase
          .auth()
          .checkActionCode(payload.oobCode)
          .then(info => {
            actionInfo = info;
            return firebase.auth().applyActionCode(payload.oobCode);
          })
          .then(() => {
            commit("setLoading", false);
            resolve(actionInfo);
          })
          .catch(function(error) {
            commit("setLoading", false);
            commit("setError", error);
          });
      });
    },
    verifyPasswordResetLink({ commit }, payload) {
      commit("setLoading", true);
      commit("clearError");
      return new Promise(resolve => {
        firebase
          .auth()
          .verifyPasswordResetCode(payload.oobCode)
          .then(email => {
            commit("setLoading", false);
            resolve(email);
          })
          .catch(function(error) {
            commit("setLoading", false);
            commit("setError", error);
          });
      });
    },
    resetPassword({ commit }, payload) {
      commit("setLoading", true);
      commit("clearError");
      return new Promise(resolve => {
        firebase
          .auth()
          .confirmPasswordReset(payload.oobCode, payload.newPassword)
          .then(() => {
            commit("setLoading", false);
            resolve();
          })
          .catch(function(error) {
            commit("setLoading", false);
            commit("setError", error);
          });
      });
    }
  }
};
