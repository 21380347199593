export default {
  title: "7-persons Lean Game",
  icon: "dots-hexagon",
  id: "7pleandots",
  description: "Dots sheets with 4 colors - for 7 players",
  type: "leangame",
  config: {
    params: {
      game_start_at: null,
      game_end_at: null,
      playTimer: false,
      timer: 3000,
      allowComputer: true,
      nextRank: {},
      configMessages: { allowChat: true },
      moveCellTime: 2000,
      improvements: {
        batch: true,
        movestations: true,
        autoinspect: true,
        balancestations: true,
        heijunka: true,
        kanban: true,
        smed: true,
        pokayoke: true
      },
      consumerDemandParams: {
        totalDuration: 20,
        visibility: false,
        firstonly: true,
        orderFreqSec: 60,
        objLTSec: 300,
        levelMix: false,
        levelQty: false,
        levelQtyPcs: 1
      },
      consumerDemand: [
        {
          created_min: 0,
          obj_lead_time: 5,
          fulfilled_at: null,
          template_id: "A",
          qty: 4
        },
        {
          created_min: 1,
          obj_lead_time: 5,
          fulfilled_at: null,
          template_id: "A",
          qty: 8
        },
        {
          created_min: 2,
          obj_lead_time: 5,
          fulfilled_at: null,
          template_id: "A",
          qty: 12
        },
        {
          created_min: 3,
          obj_lead_time: 5,
          fulfilled_at: null,
          template_id: "A",
          qty: 16
        },
        {
          created_min: 4,
          obj_lead_time: 5,
          fulfilled_at: null,
          template_id: "A",
          qty: 12
        },
        {
          created_min: 5,
          obj_lead_time: 5,
          fulfilled_at: null,
          template_id: "A",
          qty: 8
        },
        {
          created_min: 6,
          obj_lead_time: 5,
          fulfilled_at: null,
          template_id: "A",
          qty: 12
        },
        {
          created_min: 7,
          obj_lead_time: 5,
          fulfilled_at: null,
          template_id: "A",
          qty: 8
        },

        {
          created_min: 8,
          obj_lead_time: 5,
          fulfilled_at: null,
          template_id: "B",
          qty: 4
        },
        {
          created_min: 9,
          obj_lead_time: 5,
          fulfilled_at: null,
          template_id: "B",
          qty: 4
        },
        {
          created_min: 10,
          obj_lead_time: 5,
          fulfilled_at: null,
          template_id: "B",
          qty: 8
        },
        {
          created_min: 11,
          obj_lead_time: 5,
          fulfilled_at: null,
          template_id: "B",
          qty: 6
        },
        {
          created_min: 12,
          obj_lead_time: 5,
          fulfilled_at: null,
          template_id: "B",
          qty: 8
        },
        {
          created_min: 13,
          obj_lead_time: 5,
          fulfilled_at: null,
          template_id: "B",
          qty: 4
        },
        {
          created_min: 14,
          obj_lead_time: 5,
          fulfilled_at: null,
          template_id: "B",
          qty: 2
        },
        {
          created_min: 15,
          obj_lead_time: 5,
          fulfilled_at: null,
          template_id: "B",
          qty: 4
        }
      ],
      currency: "$",
      mapSize: [5, 4],
      costTypes: {
        station: -10,
        opes: {
          // Successful:-1,
          // Failed:-1,
          Rework: -20
        },
        rawmaterial: -10,
        latepenalty: -40,
        qualitypenalty: -50,
        wip: -50,
        // fg: 0,
        sales: 100
      },
      productsTemplates: {
        A: {
          name: "Product A",
          batchSize: 4,
          template_id: "A",
          type: "dotsSheet",
          hideStatus: false,
          showGuides: false,
          design: {
            columns: [
              { label: "Green", dots: [] },
              { label: "Red", dots: [] },
              { label: "Blue", dots: [] },
              { label: "Yellow", dots: [] }
            ],
            targetRadius: 4,
            addTraces: []
          },
          operations: [
            {
              ope: "painting",
              id: "s1",
              status: null,
              params: {
                shape: "circle",
                color: "green",
                position: [0, 0]
              }
            },
            {
              ope: "painting",
              id: "s2",
              status: null,
              params: {
                shape: "circle",
                color: "green",
                position: [0, 1]
              }
            },
            {
              ope: "painting",
              id: "s3",
              status: null,
              params: {
                shape: "circle",
                color: "red",
                position: [1, 0]
              }
            },
            {
              ope: "painting",
              id: "s4",
              status: null,
              params: {
                shape: "square",
                color: "red",
                position: [1, 1]
              }
            },
            {
              ope: "painting",
              id: "s5",
              status: null,
              params: {
                shape: "circle",
                color: "red",
                position: [1, 2]
              }
            },
            {
              ope: "painting",
              id: "s6",
              status: null,
              params: {
                shape: "square",
                color: "red",
                position: [1, 3]
              }
            },
            {
              ope: "painting",
              id: "s7",
              status: null,
              params: {
                shape: "circle",
                color: "red",
                position: [1, 4]
              }
            },
            {
              ope: "painting",
              id: "s8",
              status: null,
              params: {
                shape: "circle",
                color: "blue",
                position: [2, 0]
              }
            },
            {
              ope: "painting",
              id: "s9",
              status: null,
              params: {
                shape: "circle",
                color: "yellow",
                position: [3, 0]
              }
            },
            {
              ope: "painting",
              id: "s10",
              status: null,
              params: {
                shape: "circle",
                color: "yellow",
                position: [3, 1]
              }
            },
            {
              ope: "painting",
              id: "s11",
              status: null,
              params: {
                shape: "square",
                color: "yellow",
                position: [3, 2]
              }
            }
          ]
        },
        B: {
          name: "Product B",
          type: "dotsSheet",
          batchSize: 4,
          template_id: "B",
          hideStatus: false,
          showGuides: false,
          design: {
            columns: [
              { label: "Green", dots: [] },
              { label: "Red", dots: [] },
              { label: "Blue", dots: [] },
              { label: "Yellow", dots: [] }
            ],
            targetRadius: 4,
            addTraces: []
          },
          operations: [
            {
              ope: "painting",
              id: "s1",
              status: null,
              params: {
                shape: "circle",
                color: "green",
                position: [0, 0]
              }
            },
            {
              ope: "painting",
              id: "s2",
              status: null,
              params: {
                shape: "square",
                color: "red",
                position: [1, 0]
              }
            },
            {
              ope: "painting",
              id: "s3",
              status: null,
              params: {
                shape: "square",
                color: "red",
                position: [1, 1]
              }
            },
            {
              ope: "painting",
              id: "s4",
              status: null,
              params: {
                shape: "circle",
                color: "red",
                position: [1, 2]
              }
            },
            {
              ope: "painting",
              id: "s5",
              status: null,
              params: {
                shape: "square",
                color: "red",
                position: [1, 3]
              }
            },
            {
              ope: "painting",
              id: "s6",
              status: null,
              params: {
                shape: "circle",
                color: "blue",
                position: [2, 0]
              }
            },
            {
              ope: "painting",
              id: "s7",
              status: null,
              params: {
                shape: "circle",
                color: "blue",
                position: [2, 1]
              }
            },
            {
              ope: "painting",
              id: "s9",
              status: null,
              params: {
                shape: "circle",
                color: "yellow",
                position: [3, 0]
              }
            },
            {
              ope: "painting",
              id: "s10",
              status: null,
              params: {
                shape: "square",
                color: "yellow",
                position: [3, 1]
              }
            },
            {
              ope: "painting",
              id: "s11",
              status: null,
              params: {
                shape: "square",
                color: "yellow",
                position: [3, 2]
              }
            }
          ]
        }
      }
    },
    facilities: {
      "100": {
        id: "100",
        prioNextStation: "200",
        type: "inventoryShipping",
        name: "Warehouse",
        icon: "swap-horizontal-bold",
        maxCapacity: null,
        mandatoryStation: true,
        position: [0, 0],
        params: { startingStock: { A: 0, B: 0 }, kanbanCapa: null }
      },
      "200": {
        id: "200",
        prioNextStation: "300",
        type: "painting",
        name: "Painting Green",
        icon: "radiobox-marked",
        params: {
          startingStock: { A: 4, B: 0 },
          kanbanCapa: null,
          changeoverDuration: 2000,
          loadingDuration: 300,
          stampRemove: false,
          availShapes: ["circle", "square"],
          availColors: ["green"]
        },
        position: [2, 0]
      },
      "300": {
        id: "300",
        prioNextStation: "400",
        type: "painting",
        name: "Painting Red",
        icon: "radiobox-marked",
        params: {
          startingStock: { A: 4, B: 0 },
          kanbanCapa: null,
          changeoverDuration: 2000,
          loadingDuration: 300,
          stampRemove: false,
          availShapes: ["circle", "square"],
          availColors: ["red"]
        },
        position: [3, 3]
      },
      "400": {
        id: "400",
        prioNextStation: "500",
        type: "painting",
        name: "Painting Blue",
        icon: "radiobox-marked",
        params: {
          startingStock: { A: 4, B: 0 },
          kanbanCapa: null,
          loadingDuration: 300,
          changeoverDuration: 2000,
          stampRemove: false,
          availShapes: ["circle", "square"],
          availColors: ["blue"]
        },
        position: [4, 2]
      },
      "500": {
        id: "500",
        prioNextStation: "600",
        type: "painting",
        name: "Painting Yellow",
        icon: "radiobox-marked",
        params: {
          startingStock: { A: 4, B: 0 },
          kanbanCapa: null,
          loadingDuration: 300,
          stampRemove: false,
          changeoverDuration: 2000,
          availShapes: ["circle", "square"],
          availColors: ["yellow"]
        },
        position: [4, 0]
      },
      "600": {
        id: "600",
        prioNextStation: "700",
        type: "painting",
        name: "Quality Control",
        icon: "magnify",
        position: [0, 3],
        params: {
          startingStock: { A: 4, B: 0 },
          kanbanCapa: null,
          loadingDuration: 300,
          stampRemove: true,
          changeoverDuration: 2000,
          availShapes: [],
          availColors: []
        }
      },
      "700": {
        id: "700",
        prioNextStation: "100",
        type: "painting",
        name: "Painting Rework",
        icon: "radiobox-marked",
        params: {
          startingStock: { A: 4, B: 0 },
          kanbanCapa: null,
          loadingDuration: 300,
          stampRemove: false,
          changeoverDuration: 2000,
          availShapes: ["circle", "square"],
          availColors: ["green", "red", "blue", "yellow"]
        },
        position: [1, 1]
      }
    }
  }
};
