<template>
  <div id="app">
    <navbar></navbar>
    <b-loading :active="loading"></b-loading>
    <div class="router-view-wrapper">
      <router-view></router-view>
    </div>
    <bg-footer v-if="showFooter"></bg-footer>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import BgFooter from "@/components/home/Footer.vue";
import { mapGetters } from "vuex";

export default {
  name: "App",
  components: {
    Navbar,
    BgFooter
  },
  mounted() {
    this.$store.dispatch("addScript");
  },
  metaInfo() {
    return this.metaInfoData;
  },
  watch: {
    portalLook() {
      if (this.portalLook && this.portalLook.heroTitle) {
        this.metaInfoData.title = this.portalLook.heroTitle;
      }
    }
  },
  computed: {
    ...mapGetters(["loading", "portalLook"]),
    showFooter() {
      return this.$route.matched.some(record => record.meta.showFooter);
    }
  },
  data() {
    return {
      loadPage: false,
      metaInfoData: {
        title: "The Beergame App"
      }
    };
  }
};
</script>

<style lang="scss">
.modal .modal-close {
  display: block !important;
}
.footer-wrapper {
  transition: opacity 200ms;
  &.transp {
    opacity: 0;
  }
}
</style>
