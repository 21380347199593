export default {
  title: "Short Lean Game - Round 1",
  icon: "radiobox-marked",
  id: "shortlean",
  description: "Simple dots sheets - for 4 players",
  type: "leangame",
  config: {
    params: {
      game_start_at: null,
      game_end_at: null,
      playTimer: false,
      timer: 3000,
      allowComputer: true,
      nextRank: {},
      configMessages: { allowChat: true },
      moveCellTime: 2000,
      improvements: {
        batch: false,
        movestations: false,
        autoinspect: false,
        balancestations: false,
        heijunka: false,
        kanban: false,
        smed: false,
        pokayoke: false
      },
      consumerDemandParams: {
        totalDuration: 6,
        visibility: false,
        firstonly: true,
        orderFreqSec: 60,
        objLTSec: 60,
        levelMix: false,
        levelQty: false,
        levelQtyPcs: 2
      },
      consumerDemand: [
        {
          created_min: 0,
          obj_lead_time: 1,
          fulfilled_at: null,
          template_id: "A",
          qty: 4
        },
        {
          created_min: 1,
          obj_lead_time: 1,
          fulfilled_at: null,
          template_id: "A",
          qty: 12
        },
        {
          created_min: 2,
          obj_lead_time: 1,
          fulfilled_at: null,
          template_id: "A",
          qty: 8
        },
        {
          created_min: 3,
          obj_lead_time: 1,
          fulfilled_at: null,
          template_id: "B",
          qty: 4
        },
        {
          created_min: 4,
          obj_lead_time: 1,
          fulfilled_at: null,
          template_id: "B",
          qty: 12
        },
        {
          created_min: 5,
          obj_lead_time: 1,
          fulfilled_at: null,
          template_id: "B",
          qty: 8
        }
      ],
      currency: "$",
      mapSize: [3, 3],
      costTypes: {
        station: -50,
        opes: {
          // Successful:-1,
          // Failed:-1,
          Rework: -20
        },
        rawmaterial: -10,
        latepenalty: -40,
        qualitypenalty: -50,
        wip: -50,
        // fg: -50,
        sales: 100
      },
      productsTemplates: {
        A: {
          name: "Product A",
          batchSize: 4,
          template_id: "A",
          type: "dotsSheet",
          hideStatus: false,
          showGuides: false,
          design: {
            columns: [
              { label: "Green", dots: [] },
              { label: "Red", dots: [] }
              // { label: "Blue", dots: [] }
            ],
            targetRadius: 8,
            addTraces: []
          },
          operations: [
            {
              ope: "painting",
              id: "s1",
              status: null,
              params: {
                shape: "circle",
                color: "green",
                position: [0, 0]
              }
            },
            {
              ope: "painting",
              id: "s2",
              status: null,
              params: {
                shape: "circle",
                color: "red",
                position: [1, 0]
              }
            },
            {
              ope: "painting",
              id: "s3",
              status: null,
              params: {
                shape: "square",
                color: "red",
                position: [1, 1]
              }
            },
            {
              ope: "painting",
              id: "s4",
              status: null,
              params: {
                shape: "circle",
                color: "red",
                position: [1, 2]
              }
            },
            {
              ope: "painting",
              id: "s5",
              status: null,
              params: {
                shape: "square",
                color: "red",
                position: [1, 3]
              }
            }
            // {
            //   ope: "painting",
            //   id: "s7",
            //   status: null,
            //   params: {
            //     shape: "circle",
            //     color: "blue",
            //     position: [2, 0]
            //   }
            // }
          ]
        },
        B: {
          name: "Product B",
          type: "dotsSheet",
          batchSize: 4,
          template_id: "B",
          hideStatus: false,
          showGuides: false,
          design: {
            columns: [
              { label: "Green", dots: [] },
              { label: "Red", dots: [] }
              // { label: "Blue", dots: [] }
            ],
            targetRadius: 8,
            addTraces: []
          },
          operations: [
            {
              ope: "painting",
              id: "s0",
              status: null,
              params: {
                shape: "circle",
                color: "green",
                position: [0, 0]
              }
            },
            {
              ope: "painting",
              id: "s1",
              status: null,
              params: {
                shape: "circle",
                color: "green",
                position: [0, 1]
              }
            },
            {
              ope: "painting",
              id: "s2",
              status: null,
              params: {
                shape: "square",
                color: "red",
                position: [1, 0]
              }
            },
            {
              ope: "painting",
              id: "s3",
              status: null,
              params: {
                shape: "square",
                color: "red",
                position: [1, 1]
              }
            },
            {
              ope: "painting",
              id: "s4",
              status: null,
              params: {
                shape: "circle",
                color: "red",
                position: [1, 2]
              }
            }
            // {
            //   ope: "painting",
            //   id: "s6",
            //   status: null,
            //   params: {
            //     shape: "circle",
            //     color: "blue",
            //     position: [2, 0]
            //   }
            // },
            // {
            //   ope: "painting",
            //   id: "s7",
            //   status: null,
            //   params: {
            //     shape: "square",
            //     color: "blue",
            //     position: [2, 1]
            //   }
            // }
          ]
        }
      }
    },
    facilities: {
      "100": {
        id: "100",
        prioNextStation: "200",
        type: "inventoryShipping",
        name: "Warehouse",
        icon: "swap-horizontal-bold",
        maxCapacity: null,
        mandatoryStation: true,
        position: [0, 0],
        params: { startingStock: { A: 0, B: 0 }, kanbanCapa: null }
      },
      "200": {
        id: "200",
        prioNextStation: "300",
        type: "painting",
        name: "Painting Green",
        icon: "radiobox-marked",
        params: {
          startingStock: { A: 4, B: 0 },
          kanbanCapa: null,
          changeoverDuration: 2000,
          loadingDuration: 300,
          stampRemove: false,
          availShapes: ["circle"],
          availColors: ["green"]
        },
        position: [2, 0]
      },
      "300": {
        id: "300",
        prioNextStation: "400",
        type: "painting",
        name: "Painting Red",
        icon: "radiobox-marked",
        params: {
          startingStock: { A: 4, B: 0 },
          kanbanCapa: null,
          changeoverDuration: 2000,
          loadingDuration: 300,
          stampRemove: false,
          availShapes: ["circle", "square"],
          availColors: ["red"]
        },
        position: [0, 2]
      },
      // "400": {
      //   id: "400",
      //   prioNextStation: "500",
      //   type: "painting",
      //   name: "Painting Blue",
      //   icon: "radiobox-marked",
      //   params: {
      //     kanbanCapa: null,
      //     loadingDuration: 300,
      //     changeoverDuration: 2000,
      //     stampRemove: false,
      //     availShapes: ["circle", "square"],
      //     availColors: ["blue"]
      //   },
      //   position: [3, 1]
      // },
      "400": {
        id: "400",
        prioNextStation: "100",
        type: "painting",
        name: "Quality & Rework",
        icon: "magnify",
        params: {
          startingStock: { A: 4, B: 0 },
          kanbanCapa: null,
          loadingDuration: 300,
          stampRemove: true,
          changeoverDuration: 2000,
          availShapes: ["circle", "square"],
          availColors: ["green", "red"]
        },
        position: [2, 2]
      }
    }
  }
};
