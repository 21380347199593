import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/functions";
import "firebase/performance";
import "firebase/analytics";

// Initialize Firebase
const config = {
  apiKey: "AIzaSyBc-IkHTeiFcn56_LeW143xh09Tr1qy1fo",
  authDomain: "bgapp-2.firebaseapp.com",
  databaseURL: "https://bgapp-2.firebaseio.com",
  projectId: "bgapp-2",
  storageBucket: "bgapp-2.appspot.com",
  messagingSenderId: "852719985660",
  // measurementId: "G-P83XVL0233",
  appId: "1:852719985660:web:36bfeae700497725"
};

firebase.initializeApp(config);

firebase.performance();

firebase.analytics();

if (location.hostname === "localhost") {
  // firebase.firestore().settings({
  //   host: "localhost:9003",
  //   ssl: false
  // });
  // firebase.functions().useFunctionsEmulator("http://localhost:5001");
}

export default firebase;
