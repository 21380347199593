export default {
  title: "Smartphone Online Business",
  icon: "cellphone",
  id: "smarttemp",
  description:
    "3-stages supply-chain with Revenue, in an e-commerce environment",
  type: "beergame",
  config: {
    params: {
      totalWeeks: 20,
      transparent: false,
      timer: 60000,
      playTimer: false,
      product: "smartphone",
      timeUnit: "day",
      consumerDemand: [
        4,
        4,
        4,
        4,
        18,
        14,
        8,
        5,
        4,
        4,
        4,
        10,
        10,
        9,
        9,
        9,
        9,
        6,
        4,
        4
      ],
      configMessages: {
        allowChat: false,
        backlogMessage: {
          sentTo: [],
          active: true,
          message:
            "Watch out, you didn't have enough stock to fulfill the demand !",
          moreThan: 0
        },
        stockMessage: {
          sentTo: [],
          active: true,
          message:
            "Try to keep a reasonable amount of stock as it impacts your costs !",
          moreThan: 50
        },
        scheduleList: [
          {
            applyWeek: 0,
            message:
              "It's time to send your Order! Decide the quantity based on the previous demand, your stock and future planned deliveries.",
            to: ["all"]
          },
          {
            applyWeek: 4,
            message:
              "The marketing team has decided to organize a big promotion for 'Black Friday' tomorrow : -50% on all smartphones !",
            to: ["all"]
          },
          {
            applyWeek: 11,
            message:
              "As christmas approaches, the delivery is now Free for all online orders for a few days 🎅",
            to: ["all"]
          }
        ]
      },
      currency: "$",
      debriefContent: [
        "Global Results",
        "Fill Rate",
        "Facility Charts",
        "Orders/Stock Charts",
        "Variability",
        "Lead times",
        "To Go Further"
      ]
    },
    facilities: {
      "1": {
        type: "shop",
        name: "E-Retailer",
        icon: "web",
        id: "1",
        supplier_id: "200",
        validatedOrder: false,
        stock: 12,
        backlog: 0,
        cost: 0,
        revenue: 0,
        allowBacklog: false,
        unitPrice: 10,
        moq: 0,
        batchSize: 0,
        stockCost: 1,
        backlogCost: 0,
        missedSaleCost: 3,
        visibleWeeks: 1,
        startOrders: 4,
        ordertime: 1,
        leadtime: 2
      },
      "200": {
        type: "warehouse",
        name: "Freight Provider",
        icon: "ferry",
        id: "200",
        supplier_id: "400",
        client_id: "1",
        validatedOrder: false,
        stock: 12,
        backlog: 0,
        cost: 0,
        revenue: 0,
        allowBacklog: true,
        unitPrice: 0,
        moq: 0,
        batchSize: 0,
        stockCost: 0.5,
        backlogCost: 1,
        missedSaleCost: 0,
        visibleWeeks: 1,
        startOrders: 4,
        ordertime: 1,
        leadtime: 5
      },
      "400": {
        type: "factory",
        name: "Phone manufacturer",
        icon: "cellphone",
        id: "400",
        client_id: "200",
        validatedOrder: false,
        stock: 12,
        backlog: 0,
        cost: 0,
        revenue: 0,
        allowBacklog: true,
        unitPrice: 0,
        moq: 0,
        batchSize: 10,
        stockCost: 0.5,
        backlogCost: 1,
        missedSaleCost: 0,
        visibleWeeks: 1,
        startOrders: 4,
        ordertime: 1,
        leadtime: 2
      }
    }
  }
};
