import firebase from "@/firebaseConfig";
import { firestoreAction } from "vuexfire";
import { vuexfireMutations } from "vuexfire";
import { DialogProgrammatic as Dialog } from "buefy";
import { ToastProgrammatic as Toast } from "buefy";

export default {
  state: {
    userGamesList: [],
    boundUserGamesList: false,
    extUserGamesList: []
  },
  getters: {
    userGamesList: state => {
      return state.userGamesList.filter(game => !game.deleted);
    },
    boundUserGamesList: state => {
      return state.boundUserGamesList;
    },
    extUserGamesList: state => {
      return state.extUserGamesList.filter(game => !game.deleted);
    }
  },
  mutations: {
    ...vuexfireMutations,
    setBoundUserGamesList(state, payload) {
      state.boundUserGamesList = payload;
    }
  },
  actions: {
    createGame({ commit, getters }, gameObj) {
      commit("setLoading", true);
      commit("clearError");
      const timestamp = firebase.firestore.FieldValue.serverTimestamp();
      return firebase
        .firestore()
        .collection("games")
        .add({
          ...gameObj,
          created_at: timestamp,
          creator: getters.currentUser.uid
        })
        .then(game => {
          commit("setLoading", false);
          return { id: game.id, ...gameObj };
        })
        .catch(error => {
          commit("setLoading", false);
          commit("setError", error);
        });
    },
    bindUserGamesList: firestoreAction((context, payload) => {
      context.commit("setLoading", true);
      return context
        .bindFirestoreRef(
          "userGamesList",
          firebase
            .firestore()
            .collection("games")
            .where("creator", "==", payload.uid)
            .where("deleted", "==", false),
          { maxRefDepth: 3 }
        )
        .then(() => {
          context.commit("setBoundUserGamesList", true);
          context.commit("setLoading", false);
        });
    }),
    bindExtUserGamesList: firestoreAction((context, payload) => {
      context.commit("setLoading", true);
      return context
        .bindFirestoreRef(
          "extUserGamesList",
          firebase
            .firestore()
            .collection("games")
            .where("creator", "==", payload.uid)
            .where("deleted", "==", false),
          { maxRefDepth: 3 }
        )
        .then(() => {
          context.commit("setLoading", false);
        });
    }),
    copyGames({ commit, getters }, gamesTab) {
      return new Promise((resolve, reject) => {
        Dialog.prompt({
          title:
            gamesTab.length > 1
              ? `Copy ${gamesTab.length} games`
              : `Copy game ${gamesTab[0].name}`,
          inputAttrs: {
            type: "number",
            required: true,
            value: 1,
            min: 1,
            max: 50
          },
          message: `How many copies do you want to create ?`,
          confirmText: "Continue",
          type: "is-success",
          onConfirm: nbCopies => {
            Dialog.prompt({
              title:
                gamesTab.length > 1
                  ? `Copy ${gamesTab.length} games`
                  : `Copy game ${gamesTab[0].name}`,
              message: `<div class="content">This will create ${
                nbCopies > 1
                  ? `<b>${nbCopies} copies</b> back to their initial state. Choose a new name for the serie ('<em>New name 1, 2, etc.</em>') or leave it blank to name them '<em>Old name - copy 1, 2, etc.</em>'`
                  : gamesTab.length > 1
                  ? `a copy of the games back to their initial state. Choose a new name for the serie ('<em>New name 1, 2, etc.</em>') or leave it blank to name them '<em>Old name - copy</em>'`
                  : `a copy of the game back to its initial state. Change its name or leave it blank to call it : '<em>${gamesTab[0].name} - copy</em>'</div>`
              }`,
              inputAttrs: {
                placeholder: `New name`,
                required: false
              },
              confirmText: "Copy",
              type: "is-success",
              onConfirm: newName => {
                commit("setLoading", true);
                commit("clearError");
                let copyPromises = [];
                gamesTab.forEach((ccGame, indGame) => {
                  for (let ii = 0; ii < nbCopies; ii++) {
                    let ccObj = JSON.parse(JSON.stringify(ccGame));
                    ccObj.created_at = firebase.firestore.FieldValue.serverTimestamp();
                    ccObj.finished = false;
                    ccObj.archived = false;
                    ccObj.params.playTimer = false;
                    ccObj.name = newName
                      ? nbCopies > 1
                        ? `${newName} ${indGame * nbCopies + (ii + 1)}`
                        : newName
                      : nbCopies > 1
                      ? `${ccObj.name} - copy ${ii + 1}`
                      : `${ccObj.name} - copy`;
                    ccObj.creator = getters.currentUser.id;
                    ccObj.players = [];
                    ccObj.creatorName = null;
                    ccObj.myGame = null;
                    Object.values(ccObj.facilities).forEach(faci => {
                      faci.user = null;
                      faci.playerName = null;
                      if (ccObj.type === "beergame") {
                        faci.validatedOrder = false;
                        if (faci.startMetrics) {
                          faci.stock = faci.startMetrics.stock;
                          faci.backlog = faci.startMetrics.backlog;
                          faci.cost = faci.startMetrics.cost;
                          faci.revenue = faci.startMetrics.revenue;
                        } else {
                          faci.stock = 12;
                          faci.backlog = 0;
                          faci.cost = 0;
                          faci.revenue = 0;
                        }
                      }
                    });
                    if (ccObj.type === "beergame") {
                      ccObj.week = 0;
                    }
                    if (ccObj.type === "leangame") {
                      ccObj.params.nextRank = {};
                      ccObj.params.game_start_at = null;
                      ccObj.params.game_end_at = null;
                      ccObj.params.consumerDemand.forEach(dem => {
                        dem.fulfilled_at = null;
                      });
                    }
                    copyPromises.push(
                      firebase
                        .firestore()
                        .collection("games")
                        .add({
                          ...ccObj
                        })
                    );
                  }
                });
                Promise.all(copyPromises)
                  .then(() => {
                    commit("setLoading", false);
                    Toast.open({
                      message: "Copied Successfully !",
                      duration: 1500
                    });
                    resolve(true);
                  })
                  .catch(error => {
                    commit("setLoading", false);
                    commit("setError", error);
                    reject(error);
                  });
              }
            });
          }
        });
      });
    },
    deleteGames({ commit, dispatch }, gamesTab) {
      commit("clearError");
      return new Promise(resolve => {
        Dialog.prompt({
          title: `Delete ${gamesTab.length} game${
            gamesTab.length > 1 ? "s" : ""
          }`,
          inputAttrs: {
            type: "number",
            min: 1,
            placeholder: gamesTab.length,
            required: true
          },
          message: `<div class="content"><em>"${gamesTab[0].name}"${
            gamesTab.length > 1
              ? " and " + (gamesTab.length - 1) + " other"
              : ""
          }${
            gamesTab.length > 2 ? "s" : ""
          }</em><br>Type the number of games to confirm.<br>This action can't be undone.</div>`,
          confirmText: "Delete",
          trapFocus: true,
          type: "is-danger",
          hasIcon: true,
          closeOnConfirm: false,
          onConfirm: (val, { close }) => {
            if (val.toString() === gamesTab.length.toString()) {
              return dispatch("disconnectPlayers", gamesTab)
                .then(hasDiscoPlayers => {
                  commit("setLoading", true);
                  commit("clearError");
                  if (hasDiscoPlayers) {
                    return dispatch(
                      "updateGameDoc",
                      gamesTab.map(gg => {
                        return {
                          gid: gg.id,
                          obj: {
                            deleted: true
                          }
                        };
                      })
                    );
                  } else {
                    close();
                    return false;
                  }
                })
                .then(checkDone => {
                  commit("setLoading", false);
                  if (checkDone) {
                    Toast.open(
                      `The game${
                        gamesTab.length > 1 ? "s" : ""
                      } have been deleted`
                    );
                  }
                  close();
                  resolve();
                })
                .catch(error => {
                  close();
                  commit("setLoading", false);
                  commit("setError", error);
                });
            } else {
              Toast.open(
                `Type the number of games selected: ${gamesTab.length}`
              );
            }
          }
        });
      });
    },
    disconnectPlayers({ commit }, gamesTab) {
      let obj = {
        totActivePlayers: 0,
        totFinishedPlayers: 0,
        totPlayers: 0,
        totGames: 0,
        tab: []
      };
      gamesTab.forEach(game => {
        let sawUser = false;
        Object.values(game.facilities).forEach(faci => {
          if (faci.user) {
            sawUser = true;
            obj.totPlayers += 1;
            obj.totActivePlayers += game.finished ? 0 : 1;
            obj.totFinishedPlayers += game.finished ? 1 : 0;
            obj.tab.push({
              id: game.id,
              roleId: faci.id,
              user_id: faci.user
            });
          }
        });
        obj.totGames += sawUser ? 1 : 0;
      });
      if (!obj.totPlayers) {
        return Promise.resolve(true);
      } else {
        return new Promise((resolve, reject) => {
          return Dialog.confirm({
            title: `Are you sure ?`,
            message:
              obj.totPlayers > 1
                ? `This will disconnect <b>${obj.totPlayers} players</b>:<ul><li><b>- ${obj.totActivePlayers}</b> from an <b>active</b> game</li><li><b>- ${obj.totFinishedPlayers}</b> from a <b>finished</b> game</li></ul>`
                : `This will disconnect <b>1 player</b> from <b>
                  his ${obj.totActivePlayers > 0 ? "active" : "finished"}
                </b> game.`,
            confirmText: "Disconnect",
            trapFocus: true,
            type: "is-warning",
            closeOnConfirm: false,
            onConfirm: (val, contextObj) => {
              let quitFunc = firebase.functions().httpsCallable("quitGame");
              let updatePromises = [];
              commit("setLoading", true);
              commit("clearError");
              obj.tab.forEach(quitObj => {
                updatePromises.push(quitFunc(quitObj));
              });
              return Promise.all(updatePromises)
                .then(() => {
                  Toast.open(
                    `The player${
                      obj.totPlayers > 1 ? "s have" : " has"
                    } been disconnected`
                  );
                  commit("setLoading", false);
                  contextObj.close();
                  resolve(true);
                })
                .catch(error => {
                  contextObj.close();
                  commit("setLoading", false);
                  commit("setError", error);
                  reject(error);
                });
            },
            onCancel() {
              resolve(false);
            }
          });
        });
      }
    }
  }
};
