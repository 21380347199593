import firebase from "@/firebaseConfig";
import { firestoreAction } from "vuexfire";

export default {
  state: {
    currentGame: null,
    currentMessages: [],
    currentOrders: null,
    currentStats: null,
    showOnboarding: false,
    showStudentView: true,
    showNewTurnDetails: false,
    autoShowNewTurnModal: true,
    showMessages: false
  },
  getters: {
    currentGame: state => {
      return state.currentGame;
    },
    currentMessages: (state, getters) => {
      let copyTab = [...state.currentMessages];
      return copyTab
        .sort((a, b) => {
          let res =
            a.show_at && b.show_at ? a.show_at.seconds - b.show_at.seconds : 1;
          if (res === 0) {
            res = a.show_at.nanoseconds - b.show_at.nanoseconds;
          }
          return res;
        })
        .filter(mess => {
          return (
            mess.show_at &&
            (!getters.myFaci ||
              mess.to.some(
                faciId => faciId === getters.myFaci.id || faciId === "all"
              ))
          );
        });
    },
    showOnboarding: state => {
      return state.showOnboarding;
    },
    showNewTurnDetails: state => {
      return state.showNewTurnDetails;
    },
    autoShowNewTurnModal: state => {
      return state.autoShowNewTurnModal;
    },
    showMessages: state => {
      return state.showMessages;
    },
    currentOrders: state => {
      return state.currentOrders;
    },
    currentStats: state => {
      return state.currentStats;
    },
    myFaci: (state, getters) => {
      if (
        getters.currentUser &&
        getters.currentUser.playingGame &&
        getters.currentGame &&
        getters.currentGame.players &&
        getters.currentGame.players.includes(getters.currentUser.id)
      ) {
        return getters.currentGame.facilities[
          getters.currentUser.playingGame.roleId
        ];
      }
      return false;
    },
    currentFacilities: state => {
      if (!state.currentGame) {
        return [];
      }
      return Object.values(state.currentGame.facilities).sort((a, b) => {
        return parseInt(a.id) - parseInt(b.id);
      });
    },
    connectedUsers: (state, getters) => {
      return getters.currentFacilities.filter(faci => {
        return faci.user;
      });
    },
    isCreator: (state, getters) => {
      return (
        getters.currentUser &&
        getters.currentGame &&
        getters.currentGame.creator == getters.currentUser.id
      );
    },
    showStudentView: state => {
      return state.showStudentView;
    },
    showAllData: (state, getters) => {
      return (
        getters.currentGame.params.transparent ||
        (getters.isCreator && !state.showStudentView) ||
        (!getters.isCreator && !getters.myFaci)
      );
    }
  },
  mutations: {
    setShowStudentView: (state, payload) => {
      state.showStudentView = payload;
    },
    setShowOnboarding: (state, payload) => {
      state.showOnboarding = payload;
    },
    setShowNewTurnDetails: (state, payload) => {
      state.showNewTurnDetails = payload;
      if (payload) {
        state.showMessages = false;
      }
    },
    setAutoShowNewTurnModal: (state, payload) => {
      state.autoShowNewTurnModal = payload;
    },
    setShowMessages: (state, payload) => {
      state.showMessages = payload;
    }
  },
  actions: {
    createOrder({ commit }, payload) {
      commit("setLoading", true);
      commit("clearError");
      const timestamp = firebase.firestore.FieldValue.serverTimestamp();
      let batch = firebase.firestore().batch();
      let newOrderRef = firebase
        .firestore()
        .collection("games")
        .doc(payload.game_id)
        .collection("orders")
        .doc();
      let gameRef = firebase
        .firestore()
        .collection("games")
        .doc(payload.game_id);
      batch.set(newOrderRef, { ...payload, created_at: timestamp });
      batch.update(gameRef, {
        [`facilities.${payload.faci_id}.validatedOrder`]: true
      });
      return batch
        .commit()
        .then(() => {
          return commit("setLoading", false);
        })
        .catch(error => {
          commit("setLoading", false);
          commit("setError", error);
          return error;
        });
    },
    async sendMessage(context, payload) {
      const timestamp = firebase.firestore.FieldValue.serverTimestamp();
      return firebase
        .firestore()
        .collection("games")
        .doc(payload.game_id)
        .collection("messages")
        .add({ ...payload, show_at: timestamp })
        .then(() => {
          return payload;
        });
    },
    async joinGame({ commit, dispatch, getters }, payload) {
      commit("setLoading", true);
      if (!getters.currentUser) {
        await dispatch("loginAnonymously");
      } else if (getters.currentUser.playingGame) {
        if (
          getters.currentUser.playingGame.id === payload.id &&
          getters.currentUser.playingGame.roleId === payload.roleId
        ) {
          commit("setLoading", false);
          return payload;
        }
      }
      if (firebase.auth().currentUser) {
        let joinFunc = firebase.functions().httpsCallable("joinAs");
        return joinFunc({
          ...payload,
          user_id: firebase.auth().currentUser.uid
        })
          .then(() => {
            commit("setLoading", false);
            dispatch("bindOrders", { id: payload.id, force: true });
            return payload;
          })
          .catch(error => {
            throw error;
          });
      } else {
        throw {
          message: "Couldn't connect, please try again"
        };
      }
    },
    quitGame({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit("setLoading", true);
        if (!payload) {
          resolve(true);
        } else {
          let quitFunc = firebase.functions().httpsCallable("quitGame");
          quitFunc({
            user_id: payload.user_id
              ? payload.user_id
              : firebase.auth().currentUser.uid,
            ...payload
          })
            .then(() => {
              resolve(payload);
              commit("setLoading", false);
            })
            .catch(error => {
              reject(error);
              commit("removeUser", false);
              commit("setLoading", false);
            });
        }
      });
    },
    startBgTimer({ commit }, payload) {
      commit("setLoading", true);
      commit("clearError");
      let updatePromises = [];
      let createTaskFunc = firebase
        .functions()
        .httpsCallable("createNewTurnTask");
      payload.forEach(gameObj => {
        updatePromises.push(
          firebase
            .firestore()
            .collection("games")
            .doc(gameObj.id)
            .update({
              [`params.playTimer`]: true
            })
        );
        updatePromises.push(
          createTaskFunc({
            gid: gameObj.id,
            type: gameObj.type,
            timer: gameObj.params.timer
          })
        );
      });
      return Promise.all(updatePromises)
        .then(() => {
          commit("setLoading", false);
          return payload;
        })
        .catch(error => {
          commit("setLoading", false);
          commit("setError", error);
        });
    },
    updateGameDoc: firestoreAction(({ commit }, payload) => {
      commit("setLoading", true);
      commit("clearError");
      let updatePromises = [];
      payload.forEach(gameObj => {
        let newObj = { ...gameObj.obj };
        // Act adding
        updatePromises.push(
          firebase
            .firestore()
            .collection("games")
            .doc(gameObj.gid)
            .update(newObj)
        );
      });
      return Promise.all(updatePromises)
        .then(() => {
          commit("setLoading", false);
          return payload;
        })
        .catch(error => {
          commit("setLoading", false);
          commit("setError", error);
        });
    }),
    updateGameStartingOrders: firestoreAction(async ({ commit }, payload) => {
      commit("setLoading", true);
      let resetOrdersFunc = firebase
        .functions()
        .httpsCallable("resetStartingOrders");
      resetOrdersFunc(payload).then(() => {
        commit("setLoading", false);
        return true;
      });
    }),
    bindCurrentGame: firestoreAction((context, payload) => {
      context.commit("setLoading", true);
      return context
        .bindFirestoreRef(
          "currentGame",
          firebase
            .firestore()
            .collection("games")
            .doc(payload.id)
        )
        .then(game => {
          context.commit("setLoading", false);
          return game;
        });
    }),
    bindOrders: firestoreAction((context, payload) => {
      if (
        !payload.force &&
        context.state.currentOrders &&
        context.state.currentOrders.length &&
        context.state.currentOrders[0].game_id == payload.id
      ) {
        return true;
      }
      context.commit("setLoading", true);
      return context
        .bindFirestoreRef(
          "currentOrders",
          firebase
            .firestore()
            .collection("games")
            .doc(payload.id)
            .collection("orders")
            .where("ongoing", "==", true)
        )
        .then(ret => {
          context.commit("setLoading", false);
          return ret;
        });
    }),
    bindMessages: firestoreAction((context, payload) => {
      if (
        !payload.force &&
        context.state.currentMessages &&
        context.state.currentMessages.length &&
        context.state.currentMessages[0].game_id == payload.id
      ) {
        return true;
      }
      context.commit("setLoading", true);
      return context
        .bindFirestoreRef(
          "currentMessages",
          firebase
            .firestore()
            .collection("games")
            .doc(payload.id)
            .collection("messages")
        )
        .then(ret => {
          context.commit("setLoading", false);
          return ret;
        });
    }),
    bindStats: firestoreAction((context, payload) => {
      if (
        !payload.force &&
        context.state.currentStats &&
        context.state.currentStats.length &&
        context.state.currentStats[0].game_id == payload.id
      ) {
        return true;
      }
      context.commit("setLoading", true);
      return context
        .bindFirestoreRef(
          "currentStats",
          firebase
            .firestore()
            .collection("games")
            .doc(payload.id)
            .collection("stats")
        )
        .then(ret => {
          context.commit("setLoading", false);
          return ret;
        });
    })
  }
};
