import firebase from "@/firebaseConfig";
import { firestoreAction } from "vuexfire";
import tinycolor from "tinycolor2";

export default {
  state: {
    showNavbar: false,
    currentWorkspace: null,
    custoLookId: null,
    showCustoLook: false,
    portalLook: null
  },
  getters: {
    showNavbar: state => {
      return state.showNavbar;
    },
    portalLook: state => {
      return state.portalLook;
    },
    custoLookId: state => {
      return state.custoLookId;
    },
    currentWorkspace: state => {
      return state.currentWorkspace;
    },
    custoLook: state => {
      let obj = {
        bckColor: "hsl(0, 0%, 100%)",
        bodyBckColor: "#FAFAFA",
        textColor: "hsl(0, 0%, 21%)",
        btnBestColor: "#ff8c89",
        // btnBestColor: "#1DB1DE",
        mainColor: "#ff8c89"
        // mainColor: "#1DB1DE"
      };
      let myLook = {
        ...state.portalLook,
        ...state.currentWorkspace
      };
      if (state.showCustoLook && myLook) {
        if (myLook.bckColor && myLook.mainColor) {
          obj = {
            ...obj,
            ...myLook,
            textColor: tinycolor
              .mostReadable(myLook.bckColor, [
                "hsl(0, 0%, 21%)",
                "hsl(0, 0%, 96%)"
              ])
              .toHexString(),
            btnBestColor: tinycolor
              .mostReadable("white", [myLook.mainColor, myLook.bckColor])
              .toHexString()
          };
        } else {
          obj = {
            ...obj,
            ...myLook
          };
        }
      }
      return obj;
    }
  },
  mutations: {
    setShowNavbar(state, payload) {
      state.showNavbar = payload;
    },
    setShowCustoLook(state, payload) {
      state.showCustoLook = payload;
    },
    setPortal(state, payload) {
      state.portalLook = payload;
      state.showCustoLook = true;
    },
    setCustoLookId(state, payload) {
      state.custoLookId = payload;
    }
  },
  actions: {
    bindCurrentWorkspace: firestoreAction((context, payload) => {
      context.commit("setLoading", true);
      let prom = context.bindFirestoreRef(
        "currentWorkspace",
        firebase
          .firestore()
          .collection("publicProfiles")
          .doc(payload.id)
      );
      if (prom) {
        return prom.then(publicProfile => {
          context.commit("setLoading", false);
          context.commit("setCustoLookId", payload.id);
          return publicProfile;
        });
      } else {
        context.commit("setLoading", false);
        return payload;
      }
    }),
    unbindCurrentWorkspace: firestoreAction(context => {
      let prop = context.unbindFirestoreRef("currentWorkspace");
      if (prop) {
        return prop.then(() => context.commit("setCustoLookId", null));
      } else {
        return context.commit("setCustoLookId", null);
      }
    }),
    updatePortal({ commit }, slug) {
      return new Promise((resolve, reject) => {
        commit("setLoading", true);
        firebase
          .firestore()
          .collection("portals")
          .doc(slug)
          .get()
          .then(portalDoc => {
            commit("setLoading", false);
            if (portalDoc.exists) {
              commit("setPortal", portalDoc.data());
            }
            return resolve(portalDoc.data());
          })
          .catch(error => {
            commit("setLoading", false);
            return reject(error);
          });
      });
    }
  }
};
