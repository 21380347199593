export default {
  title: "Aircraft Supply-Chain",
  icon: "airplane",
  id: "airtemp",
  description:
    "A more complex supply-chain with various lead times and batch sizes",
  type: "beergame",
  config: {
    params: {
      totalWeeks: 20,
      transparent: false,
      timer: 60000,
      playTimer: false,
      product: "aircraft",
      timeUnit: "month",
      consumerDemand: [
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        10,
        8,
        6,
        5,
        7,
        6,
        8,
        6,
        7,
        5,
        6
      ],
      configMessages: {
        allowChat: false,
        backlogMessage: {
          sentTo: [],
          active: true,
          message:
            "Watch out, you didn't have enough stock to fulfill the demand !",
          moreThan: 0
        },
        stockMessage: {
          sentTo: [],
          active: true,
          message:
            "Try to keep a reasonable amount of stock as it impacts your costs !",
          moreThan: 50
        },
        scheduleList: [
          {
            applyWeek: 0,
            message:
              "It's time to send your Order! Decide the quantity based on the previous demand, your stock and future planned deliveries.",
            to: ["all"]
          },
          {
            applyWeek: 3,
            message:
              "The aeronautics sector is in expansion, demand has been steadily increasing for the past few months 🚀",
            to: ["1"]
          },
          {
            applyWeek: 9,
            message:
              "New regulations increased taxes on air travel to reduce carbon emissions.. Good for the planet 🌍, but it could impact future sales.",
            to: ["all"]
          }
        ]
      },
      currency: "$",
      debriefContent: [
        "Global Results",
        "Fill Rate",
        "Facility Charts",
        "Orders/Stock Charts",
        "Variability",
        "Lead times",
        "To Go Further"
      ]
    },
    facilities: {
      "1": {
        type: "shop",
        name: "Distribution center",
        icon: "call-split",
        id: "1",
        supplier_id: "200",
        validatedOrder: false,
        stock: 16,
        backlog: 0,
        cost: 0,
        revenue: 0,
        allowBacklog: true,
        unitPrice: 0,
        moq: 5,
        batchSize: 0,
        stockCost: 0.5,
        backlogCost: 1,
        missedSaleCost: 0,
        visibleWeeks: 1,
        startOrders: 4,
        ordertime: 1,
        leadtime: 2
      },
      "200": {
        type: "warehouse",
        name: "Quality control",
        icon: "animation",
        id: "200",
        supplier_id: "300",
        client_id: "1",
        validatedOrder: false,
        stock: 16,
        backlog: 0,
        cost: 0,
        revenue: 0,
        allowBacklog: true,
        unitPrice: 0,
        moq: 0,
        batchSize: 0,
        stockCost: 0.5,
        backlogCost: 1,
        missedSaleCost: 0,
        visibleWeeks: 1,
        startOrders: 4,
        ordertime: 1,
        leadtime: 2
      },
      "300": {
        type: "warehouse",
        name: "Assembly line",
        icon: "airplane",
        id: "300",
        supplier_id: "400",
        client_id: "200",
        validatedOrder: false,
        stock: 16,
        backlog: 0,
        cost: 0,
        revenue: 0,
        allowBacklog: true,
        unitPrice: 0,
        moq: 0,
        batchSize: 0,
        stockCost: 0.5,
        backlogCost: 1,
        missedSaleCost: 0,
        visibleWeeks: 1,
        startOrders: 4,
        ordertime: 1,
        leadtime: 5
      },
      "400": {
        type: "factory",
        name: "Components supplier",
        icon: "cog",
        id: "400",
        client_id: "300",
        validatedOrder: false,
        stock: 16,
        backlog: 0,
        cost: 0,
        revenue: 0,
        allowBacklog: true,
        unitPrice: 0,
        moq: 0,
        batchSize: 10,
        stockCost: 0.5,
        backlogCost: 1,
        missedSaleCost: 0,
        visibleWeeks: 1,
        startOrders: 4,
        ordertime: 1,
        leadtime: 3
      }
    }
  }
};
