<template>
  <div class="zensimu-logo-wrapper">
    <svg
      :height="logoHeight ? logoHeight : 25"
      :viewBox="showTitle ? '0 0 1024 145' : '0 0 211 145'"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M140.102 71.5861L122.04 141.667H193.204L211.206 71.5861H140.102Z"
        fill="#4CAEDE"
      />
      <path
        d="M18.0621 1.56537L0 71.5861H71.1044L89.1665 1.56537H18.0621Z"
        fill="#EFC523"
      />
      <path
        d="M140.102 71.5861H71.1044L53.1025 141.667H122.04L140.102 71.5861Z"
        fill="#83BBA4"
      />
      <path
        d="M158.164 1.56537H89.1666L71.1045 71.5861H140.102L158.164 1.56537Z"
        fill="#D7848A"
      />
      <path
        v-if="showTitle"
        d="M277.735 115.055L348.779 26.6115H280.865V1.56537H379.304V28.1768L309.223 116.621H380.267V141.667H277.915L277.735 115.055Z"
        fill="#252849"
      />
      <path
        v-if="showTitle"
        d="M529.821 58.2803H531.387C534.349 53.4942 538.496 49.554 543.428 46.841C548.78 43.9971 554.769 42.5672 560.828 42.6867C566.246 42.5831 571.63 43.5657 576.662 45.5767C581.141 47.4638 585.09 50.4199 588.162 54.1863C591.214 58.0139 593.466 62.4159 594.785 67.1308C596.36 72.5683 597.131 78.2073 597.072 83.8683V141.968H571.424V86.698C571.885 81.187 570.251 75.7048 566.849 71.3452C565.114 69.5725 563.015 68.1969 560.697 67.3135C558.379 66.43 555.897 66.0595 553.422 66.2277C550.19 66.1493 546.994 66.9172 544.151 68.4553C541.456 69.9444 539.114 71.9982 537.287 74.476C535.334 77.1928 533.867 80.2282 532.952 83.4468C531.972 87.0572 531.486 90.7839 531.507 94.5249V141.486H505.739V45.8175H529.821V58.2803Z"
        fill="#252849"
      />
      <path
        v-if="showTitle"
        d="M655.172 144.798C649.505 144.885 643.859 144.092 638.435 142.45C633.918 141.103 629.633 139.072 625.731 136.429C622.246 133.985 619.176 130.997 616.64 127.579C614.292 124.508 612.387 121.124 610.98 117.524L633.859 107.71C635.672 112.201 638.772 116.055 642.769 118.788C646.453 121.198 650.771 122.455 655.172 122.401C659.047 122.599 662.901 121.724 666.31 119.872C667.469 119.326 668.459 118.479 669.179 117.421C669.899 116.362 670.322 115.129 670.404 113.851C670.423 112.598 670.124 111.361 669.534 110.256C668.944 109.15 668.084 108.212 667.033 107.53C663.317 105.337 659.239 103.829 654.991 103.074L641.505 100.184C638.294 99.4684 635.166 98.4192 632.173 97.0536C629.037 95.649 626.118 93.8041 623.503 91.5748C620.861 89.3212 618.674 86.5827 617.061 83.5071C615.264 80.1403 614.372 76.3645 614.472 72.5494C614.394 68.1795 615.452 63.8642 617.543 60.0263C619.636 56.2471 622.585 53.01 626.152 50.5738C629.893 47.841 634.094 45.8018 638.555 44.5532C643.55 43.1464 648.719 42.4572 653.908 42.5061C662.365 42.3334 670.742 44.1698 678.352 47.8645C685.557 51.4366 691.209 57.5171 694.246 64.9633L672.09 73.9944C670.361 70.5428 667.489 67.7978 663.962 66.2277C660.688 64.5954 657.085 63.7306 653.426 63.699C650.097 63.6069 646.8 64.3741 643.853 65.9266C642.646 66.4113 641.608 67.2407 640.869 68.3114C640.131 69.3821 639.724 70.6467 639.699 71.9473C639.724 73.0616 640.059 74.1468 640.665 75.0823C641.271 76.0177 642.124 76.7666 643.131 77.2455C646.077 78.8079 649.214 79.9817 652.463 80.7375L667.093 84.2897C675.329 85.8045 682.933 89.7218 688.948 95.5484C693.558 100.427 696.104 106.899 696.053 113.611C696.081 117.807 695.067 121.944 693.102 125.652C691.088 129.562 688.254 132.991 684.794 135.706C680.965 138.807 676.597 141.175 671.91 142.69C666.48 144.291 660.828 145.002 655.172 144.798V144.798Z"
        fill="#252849"
      />
      <path
        v-if="showTitle"
        d="M725.012 32.873C720.653 32.8717 716.474 31.1392 713.392 28.0564C711.869 26.5892 710.661 24.8276 709.84 22.8786C708.977 20.8421 708.546 18.6483 708.576 16.4365C708.546 14.2247 708.977 12.0309 709.84 9.99436C710.675 8.05322 711.882 6.29423 713.392 4.81656C716.495 1.72824 720.695 -0.0055542 725.072 -0.0055542C729.45 -0.0055542 733.65 1.72824 736.752 4.81656C738.326 6.30977 739.572 8.11378 740.412 10.1141C741.251 12.1145 741.666 14.2675 741.629 16.4365C741.666 18.6056 741.251 20.7586 740.412 22.7589C739.572 24.7592 738.326 26.5632 736.752 28.0564C735.221 29.6083 733.391 30.8348 731.374 31.6625C729.356 32.4901 727.193 32.9019 725.012 32.873V32.873ZM712.308 141.667V45.8175H737.957V141.667H712.308Z"
        fill="#252849"
      />
      <path
        v-if="showTitle"
        d="M761.438 45.8175H785.52V58.2803H787.086C790.11 53.5765 794.245 49.6892 799.127 46.9614C804.379 44.0725 810.292 42.5994 816.286 42.6867C822.78 42.46 829.187 44.2247 834.649 47.7441C839.369 50.7548 843.096 55.0896 845.366 60.207C848.637 55.2855 852.98 51.1688 858.07 48.1656C864.095 44.5509 871.036 42.753 878.058 42.9878C883.279 42.8445 888.47 43.8089 893.291 45.8175C897.428 47.5471 901.084 50.2533 903.947 53.7046C906.849 57.2577 909.018 61.3502 910.329 65.746C911.815 70.809 912.545 76.0634 912.497 81.3396V141.547H886.849V85.9153C886.849 72.8504 881.49 66.3481 870.773 66.3481C867.638 66.2772 864.541 67.0463 861.803 68.5757C859.186 70.0585 856.942 72.1174 855.24 74.5964C853.367 77.3715 851.983 80.4461 851.146 83.6877C850.108 87.3699 849.602 91.1812 849.641 95.0066V141.607H824.053V85.9153C824.053 72.8504 818.393 66.3481 807.195 66.3481C804.174 66.2759 801.192 67.0473 798.585 68.5757C796.172 70.1299 794.119 72.1828 792.564 74.5964C790.752 77.3821 789.429 80.4566 788.651 83.6877C787.714 87.3871 787.248 91.1903 787.266 95.0066V141.607H761.678L761.438 45.8175Z"
        fill="#252849"
      />
      <path
        v-if="showTitle"
        d="M999.917 129.144H998.352C995.379 133.921 991.234 137.859 986.31 140.583C980.95 143.468 974.936 144.92 968.85 144.798C963.779 145.176 958.69 144.366 953.987 142.433C949.283 140.499 945.096 137.495 941.757 133.659C935.47 125.058 932.325 114.559 932.847 103.917V45.8175H958.495V100.786C958.051 106.305 959.707 111.788 963.131 116.139C964.874 117.876 966.969 119.22 969.274 120.082C971.579 120.944 974.041 121.303 976.497 121.136C979.753 121.196 982.969 120.407 985.829 118.849C988.496 117.345 990.815 115.293 992.632 112.828C994.632 110.117 996.103 107.053 996.967 103.797C997.951 100.187 998.437 96.46 998.412 92.7187V45.8175H1024.06V141.667H999.977L999.917 129.144Z"
        fill="#252849"
      />
      <path
        v-if="showTitle"
        d="M469.614 99.5823C468.904 103.202 467.515 106.655 465.52 109.757C462.575 114.032 458.364 117.275 453.479 119.029C450.741 120.212 447.837 120.964 444.869 121.257C441.972 121.54 439.047 121.253 436.26 120.414C433.24 119.598 430.415 118.185 427.951 116.26C425.166 114.108 422.834 111.428 421.087 108.373L487.676 80.4365C487.375 79.783 487.152 79.0958 487.014 78.3895C486.74 77.7256 486.518 77.0411 486.352 76.3424L485.449 74.175C482.876 67.8972 479.297 62.081 474.852 56.9558C470.784 52.2864 465.806 48.4963 460.222 45.8175C454.57 43.1773 448.398 41.8399 442.16 41.904C435.129 41.9747 428.186 43.4699 421.75 46.2992C415.346 48.8944 409.576 52.8368 404.831 57.8589C400.409 62.5711 396.934 68.09 394.596 74.1148C392.27 80.1805 391.165 86.6461 391.345 93.1402C391.476 99.867 392.886 106.508 395.499 112.707C398.139 119.199 402.026 125.11 406.939 130.107C411.531 134.713 416.996 138.357 423.014 140.824C429.046 143.304 435.518 144.533 442.039 144.437C448.958 144.344 455.791 142.892 462.149 140.162C471.193 136.687 479.036 130.664 484.726 122.822C489.501 115.888 492.574 107.926 493.697 99.5823H469.614ZM418.258 75.4995C420.899 70.9857 424.997 67.5039 429.878 65.6256C433.026 64.2119 436.422 63.4344 439.872 63.3377C442.687 63.3008 445.485 63.7909 448.12 64.7827C450.324 65.6773 452.36 66.9395 454.141 68.5155C455.668 69.81 456.935 71.3833 457.874 73.1515L415.127 91.2135C414.444 85.7794 415.588 80.2725 418.378 75.5597L418.258 75.4995Z"
        fill="#252849"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: "zensimu-logo",
  props: {
    logoHeight: Number,
    showTitle: Boolean
  }
};
</script>

<style lang="scss" scoped>
.zensimu-logo-wrapper {
}
</style>
